import React, { useState, useEffect } from "react";
import { requestForToken, onMessageListener } from "../firebase";
import { API } from "../api-service";
import { useCookies } from "react-cookie";

const PushNotificationButton = () => {
  const [token] = useCookies(["hio-token"]);
  const [FCMToken, setFCMToken] = useState();
  const [displayedToken, setDisplayedToken] = useState("");

  const handlePermissionClick = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const FCMToken = await requestForToken();
        if (FCMToken) {
          setFCMToken(FCMToken);
          setDisplayedToken(FCMToken); // Speichern Sie den Token zur Anzeige
          sendTokenToAPI(FCMToken);
        }

        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then((registration) => {
            handleMessageListener(registration);
          })
          .catch((error) => {
            console.error("Service worker registration error:", error);
          });
      }
    } catch (error) {
      console.error("Permission request error:", error);
    }
  };

  const sendTokenToAPI = (FCMToken) => {
    API.postFCMToken(FCMToken, token["hio-token"])
      .then((resp) => {
        // Hier können Sie Aktionen ausführen, wenn der FCM-Token erfolgreich übergeben wurde
      })
      .catch((error) => {
        console.log("Fehler beim Senden des FCM-Tokens an die API:", error);
      });
  };

  const handleMessageListener = (registration) => {
    onMessageListener(registration).then((payload) => {
      console.log("Received background message ", payload);
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
      };

      registration.showNotification(notificationTitle, notificationOptions);
    });
  };

  return (
    <div>
      <button onClick={handlePermissionClick}>
        Benachrichtigungen aktivieren
      </button>
      {/*       {displayedToken && <p>Push-Token: {displayedToken}</p>}
       */}{" "}
    </div>
  );
};

export default PushNotificationButton;
