import React from "react";
import { API } from "../api-service";
import { useCookies } from "react-cookie";
import { useState } from "react";
import Exercise from "./exercise.js";

function MyExercises(props) {
  const [token] = useCookies(["hio-token"]);

  if (!props.allmyexercises) {
    // Wenn exercises noch leer ist, soll nix zurückgegeben werden
    return null;
    /*  return <div>No lesson selected</div>; */
  }

  /*   console.log(props.allmyexercises[0].exercise);
   */
  return (
    <>
      <div className="d-flex  justify-content-between ">
        <div className="d-flex">
          <h1 className="text-white ps-5 inline-h1">Meine Übungen</h1>
        </div>
      </div>

      <div className="container-fluid mt-4 mb-5 ">
        <div className="row mb-3">
          <div className="col-12 ">
            <div className="card p-4 mb-3">
              <h2>Übungen</h2>

              <div className="align-items-center">
                <div>
                  {props.allmyexercises &&
                    props.allmyexercises.map((myexercise) => (
                      <Exercise exercise={myexercise} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyExercises;
