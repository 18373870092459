import React, { useState, useEffect } from "react";
import UserDetail from "./user-detail";
import { useCookies } from "react-cookie";
import { API } from "../api-service";

function Person(props) {
  const [token] = useCookies(["hio-token"]);
  const [localAttendance, setLocalAttendance] = useState(props.user.attendance);

  useEffect(() => {
    setLocalAttendance(props.user.attendance);
  }, [props.user.attendance]);

  if (!props) {
    return null;
  }

  function OpenUser(user_id) {
    props.UserExercises(user_id);
  }

  function SetAttendance(event, user_id, lesson_id, attendance) {
    event.stopPropagation(); // Stoppt das Bubbling des Events

    let newAttendance;
    if (attendance === true) {
      newAttendance = false;
    } else {
      newAttendance = true;
    }

    API.setAttendance(user_id, lesson_id, newAttendance, token["hio-token"])
      .then((resp) => {
        setLocalAttendance(newAttendance); // Aktualisiere das lokale State-Objekt
      })
      .catch((error) => {
        console.log("Fehler beim setzen der Anwesenheit an die API:", error);
      });
  }

  // Prüft ob ein Bild vorhanden ist
  const profileImage =
    props.user.profile && props.user.profile.image
      ? props.user.profile.image
      : props.user.image
      ? props.user.image
      : null;

  return (
    <div onClick={props.isTrainer ? () => OpenUser(props.user.id) : null}>
      <div key={"per" + props.user.id}>
        <div className="schulerCard borderfull">
          <div className="d-flex align-items-center px-2 w-100 h-100">
            <div className="userPic ListImage">
              <img
                src={profileImage || "/images/no_profile.png"}
                width="50px"
                height="50px"
                alt=""
              />
            </div>
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex flex-column margin-left text-dark">
                <span className="mb-0 pb-0">
                  {props.user.first_name && props.user.last_name
                    ? `${props.user.first_name} ${props.user.last_name}`
                    : props.user.username}
                </span>
              </div>
              {/* Prüfe, ob User kein Pro ist props.isTrainer UND props.lesson_id existiert */}
              {props.isTrainer && props.lesson_id && (
                <span
                  onClick={(event) =>
                    SetAttendance(
                      event,
                      props.user.id,
                      props.lesson_id,
                      localAttendance
                    )
                  }
                >
                  {localAttendance === true
                    ? "Ist anwesend"
                    : localAttendance === false
                    ? "Nicht da"
                    : "Anwesenheit ???"}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Person;
