import React from "react";
import Person from "./person";

function TrainingDetail(props) {
  if (!props.trainingDetails) {
    return null;
    /* return <div>No Training selected</div>; */
  }

  const trainingDetails = props.trainingDetails;

  //öffnet eine einzelne Lektion
  const OpenLessonDetail = (lesson) => (evt) => {
    props.OpenLessonDetail(lesson);
  };

  return (
    <div className=" pb-5 FordertraininContainer mb-5">
      <div className="container-fluid mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="topImage ps-4">
            <img src={trainingDetails.image} alt="" />
          </div>
        </div>
        <div className="mt-2">
          <h1 className="text-white serif fw-bold ps-4">
            {trainingDetails.title}
          </h1>
          <div className="d-flex align-items-center">
            {trainingDetails.tags &&
              trainingDetails.tags.map((tag) => (
                <span key={tag}>
                  <button className="btn br-30 bg-primary">{tag.title}</button>
                </span>
              ))}
          </div>
        </div>
      </div>
      <div className="container-fluid mt-2 mb-5 ">
        <div className="card p-4 mb-3">
          <div className=" justify-content-between">
            <h4 className="fw-bold">Lektionen</h4>
            {trainingDetails.lessons &&
              trainingDetails.lessons.map((lesson) => {
                const date = new Date(lesson.date);

                const time = `${date.getUTCHours()}:${date
                  .getUTCMinutes()
                  .toString()
                  .padStart(2, "0")}`;
                const formattedDate = date.toLocaleDateString("de-DE", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                });

                return (
                  <div key={lesson.id} onClick={OpenLessonDetail(lesson)}>
                    <div className="schulerCard borderfull  ">
                      <div className="d-flex align-items-center justify-content-between px-2 w-100 h-100">
                        <div className="userPic ListImage">
                          {formattedDate} -{time}
                        </div>
                        <div>
                          <div className="d-flex flex-column text-dark">
                            <span className="mb-0 pb-0 ">
                              {lesson.title} - {lesson.duration} Min.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div mbsc-page className="demo-multiple-select">
            <div>
              <div className="mbsc-grid">
                <div className="mbsc-row">
                  <div
                    className="mbsc-col-sm-12 mbsc-col-md-4"
                    /* style="z-index: 0;" */
                  >
                    <div className="mbsc-form-group">
                      <div id="demo-multi-day"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card p-4 mb-3">
          <div className="d-flex justify-content-between">
            <h4 className="fw-bold">Schüler</h4>
          </div>
          <div>
            {trainingDetails.users &&
              trainingDetails.users.map((user) => (
                <Person
                  user={user}
                  UserExercises={props.UserExercises}
                  isTrainer={props.MyUsersDetail.is_trainer}
                />
              ))}
          </div>
        </div>

        {/* ANHàNGE UND NOTIZEN @@@*/
        /* <div className="card p-4 mb-3">
          <h4 className="fw-bold">Anhange / Notizen ???</h4>

          <div>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Lektion 1{" "}
                    <span className="text-darklightgray ms-1">(0)</span>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse "
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <h6>Anhange</h6>
                    <div className="AnhangBlock p-3 br-12 mb-3">
                      <p>Lorem, ipsum dolor sit amet consectetur elit. Qui, </p>
                      <div className="plusCircle">+</div>
                    </div>
                    <h6>Notizen</h6>
                    <div className="AnhangBlock p-3 br-12">
                      <p>Lorem, ipsum dolor sit amet consectetur elit. Qui, </p>
                      <div className="plusCircle">+</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card p-4 mb-3 mt-3">
          <h4 className="fw-bold">Kursbeschreibung</h4>
          <p>{trainingDetails.description}</p>
          <br></br>
          <h5 className="fw-bold">Ziele</h5>
          <p>{trainingDetails.goals}</p>
          <div className="row mt-3">
            {/* Kurschbeschreibung  @@@ */
            /*  <div className="col-6">
              <div className="card p-2 d-flex flex-column">
                <h6 className="fw-bold mb-0">Niveau</h6>
                <span>A</span>
              </div>
            </div>
            <div className="col-6">
              <div className="card p-2 d-flex flex-column">
                <h6 className="fw-bold mb-0">Telinehmar</h6>
                <span>B</span>
              </div>
            </div> 
            <div className="col-6 mt-2">
              <div className="card p-2 d-flex flex-column">
                <img
                  src="/images/Icons/Ebene_14.png"
                  width="20px"
                  height="20px"
                  alt=""
                />
                <span>06.09.23</span>
              </div>
            </div>
            <div className="col-6 mt-2">
              <div className="card p-2 d-flex flex-column">
                <img
                  src="/images/Icons/Ebene_13.png"
                  width="20px"
                  height="20px"
                  alt=""
                />
                <span>19.00 Uhr</span>
              </div>
            </div>*/}
            <div className="col-12 mt-2">
              <div className="card p-2 d-flex flex-column">
                <img
                  src="/images/Icons/Ebene_12.png"
                  width="20px"
                  height="20px"
                  alt=""
                />
                <span>{trainingDetails.golfcourse.name}</span>
                <span>
                  {trainingDetails.golfcourse.zip}{" "}
                  {trainingDetails.golfcourse.city}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingDetail;
