import React, { useEffect } from "react";
import { API } from "../api-service";
import { useCookies } from "react-cookie";
import { useState } from "react";

function TrainingList(props) {
  const [token] = useCookies(["hio-token"]);
  const [MyTrainings, setMyTrainings] = useState([]);

  useEffect(() => {
    loadMyTrainings();
  }, []);

  const loadMyTrainings = () => {
    API.getMyTrainings(token["hio-token"])
      .then((resp) => {
        setMyTrainings(resp);
      })
      .catch((error) => console.log(error));
  };

  if (!props.showTrainingList) {
    return null;
  }

  if (!props) {
    return null;
  }

  // Funktion, um das erste und letzte Datum einer Lesson zu finden
  const findLessonDates = (lessons) => {
    const lessonDates = lessons.map((lesson) => new Date(lesson.date));
    const minDate = new Date(Math.min(...lessonDates));
    const maxDate = new Date(Math.max(...lessonDates));
    return { from: minDate, to: maxDate };
  };

  const renderTrainingsByCategory = (categoryTitle, trainings) => {
    return (
      <div>
        <h4 className="categoryTitle">{categoryTitle}</h4>
        {trainings.map((training, index) => (
          <div
            className="card p-2 pt-0 b-2 mb-2"
            key={index}
            onClick={() => OpenTrainingDetail(training.id)}
          >
            <div className="topImage ps-4">
              <img src={training.image} alt="" />
            </div>
            <p>
              <b>{training.title}</b>{" "}
            </p>
            <div className="d-flex align-items-center">
              {training.tags &&
                training.tags.map((tag) => (
                  <span key={tag.id}>
                    <button className="btn br-30 bg-primary">
                      {tag.title}
                    </button>
                  </span>
                ))}
            </div>
            {training.description}
            <p>
              <b>Vom:</b>{" "}
              {findLessonDates(training.lessons).from.toLocaleDateString()}{" "}
              <b>bis:</b>{" "}
              {findLessonDates(training.lessons).to.toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
    );
  };

  // öffnet eine einzelnes Training
  function OpenTrainingDetail(training_id) {
    props.OpenTrainingDetail(training_id);
  }

  if (MyTrainings.length === 0) {
    return "Lade Trainings..."; // Warten, bis die Daten geladen sind.
  }

  // Filter trainings into categories
  const futureTrainings = MyTrainings.filter((training) => {
    const hasFutureLesson = training.lessons.every(
      (lesson) => new Date(lesson.date) > new Date()
    );
    return hasFutureLesson;
  });

  const ongoingTrainings = MyTrainings.filter((training) => {
    const hasPastLesson = training.lessons.some(
      (lesson) => new Date(lesson.date) < new Date()
    );
    const hasFutureLesson = training.lessons.some(
      (lesson) => new Date(lesson.date) > new Date()
    );
    return hasPastLesson && hasFutureLesson;
  });

  const pastTrainings = MyTrainings.filter((training) => {
    const hasPastLesson = training.lessons.every(
      (lesson) => new Date(lesson.date) < new Date()
    );
    return hasPastLesson;
  });

  return (
    <div className="container-fluid mt-4 mb-5">
      <div className="card p-4 mb-3">
        <h2>Meine Trainings</h2>
        {MyTrainings.length > 0 ? (
          <div>
            {ongoingTrainings.length > 0 &&
              renderTrainingsByCategory("Laufende Trainings", ongoingTrainings)}
            {futureTrainings.length > 0 &&
              renderTrainingsByCategory(
                "Zukünftige Trainings",
                futureTrainings
              )}
            {pastTrainings.length > 0 &&
              renderTrainingsByCategory("Vergangene Trainings", pastTrainings)}
          </div>
        ) : (
          "Keine Trainings verfügbar."
        )}
      </div>
    </div>
  );
}

export default TrainingList;
