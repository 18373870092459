import React, { useState, useEffect, Component } from "react";
/* import "./App.css"; */
import "./css/style.css";
import LessonsList from "./components/lessons-list.js";
import LessonsDetail from "./components/lesson-detail.js";
import Headerbar from "./components/headerbar.js";
import TrainingDetail from "./components/training-detail.js";
import ExercisesList from "./components/exercises-list.js";
import ExerciseDetail from "./components/exercise-detail.js";
/* import ExerciseForm from "./components/exercise-form.js"; */
import MyExercises from "./components/myexercises.js";
import Homework from "./components/homework";
import UserDetail from "./components/user-detail";
import ChatData from "./components/chatdata";
import Chat from "./components/chat";
import Chat_Training from "./components/Chat_Training";
import Notification from "./components/Notification";
import TrainingList from "./components/training-list";
import UserList from "./components/user-list.js";
import VideoRecorder from "./components/VideoRecorder.js";

import { useCookies } from "react-cookie";
import { useFetch } from "./hooks//useFetch";
import { API } from "./api-service";

import PublicIP from "./components/PublicIP";

function App() {
  const [lessons, setLessons] = useState([]);
  const [lessonDetails, SetlessonDetails] = useState(null);
  const [trainingDetails, SettrainingDetails] = useState(null);
  const [exercises, setExercises] = useState(null);
  const [selectExercises, setSelectExercises] = useState(null);
  const [editExercises, setEditExercises] = useState(null);
  const [token, setToken, deleteToken] = useCookies(["hio-token"]);
  const [data, loading, error] = useFetch();
  const [listmyExercises, SetlistmyExercises] = useState(null);
  const [singleuser, Setsingleuser] = useState(null);
  const [userExercise, SetuserExercise] = useState(null);
  const [chat_data, SetChat_data] = useState(null);
  const [selectedChatUserId, setselectedChatUserId] = useState(null);
  const [selectedChatTrainingId, setselectedChatTrainingId] = useState(null);
  const [chatName, setChatName] = useState(null);
  const [ShowTrainingList, setShowTrainingList] = useState(false);
  const [TrainingUserData, setTrainingUserData] = useState([]);

  //startet den Homescreen mit den Lessons (siehe auch useFetch.js)
  useEffect(() => {
    setLessons(data);
  }, [data]);

  //Prüft ob ein Token vorhanden ist. Wenn nicht, dann wird die Login Seite aufgerufen
  useEffect(() => {
    //Wenn ob kein Token vorhanden ist, wird man  zu exercises weitergeleitet. Das müsste dann später mein "Home" Seite sein
    if (!token["hio-token"]) window.location.href = "/";
  }, [token]);

  //Wird verwendet um eine Lektion anzuzeigen
  const loadLesson = (lesson) => {
    API.getSingleLesson(lesson.id, token["hio-token"])
      .then((resp) => SetlessonDetails(resp))
      .catch((error) => console.log());
    setLessons(null);
    SettrainingDetails(null);
    setShowTrainingList(false);
  };

  const loadTraining = (training_id) => {
    API.getSingleTraining(training_id, token["hio-token"])
      .then((resp) => SettrainingDetails(resp))
      .catch((error) => console.log());
    setLessons(null);
    SetlistmyExercises(null);
    SetlessonDetails(null);
    setExercises(null);
    setSelectExercises(null);
    setEditExercises(null);
    setselectedChatUserId(null);
    setselectedChatTrainingId(null);
    setShowTrainingList(false);
    setTrainingUserData([]);
  };

  const MyUserDetail = () => {
    API.getMyUserDetail(token["hio-token"])
      .then((resp) => SetMyUsersDetails(resp))
      .catch((error) => console.log());
  };

  const [MyUsersDetail, SetMyUsersDetails] = useState(MyUserDetail);

  const loadMyTasks = () => {
    API.getMyTasks(token["hio-token"])
      .then((resp) => SetmyTasks(resp))
      .catch((error) => console.log());
  };

  const [myTasks, SetmyTasks] = useState(loadMyTasks);

  const loadMyHomework = () => {
    API.getMyHomework(token["hio-token"])
      .then((resp) => setMyHomework(resp))
      .catch((error) => console.log());
  };

  const [MyHomework, setMyHomework] = useState(loadMyHomework);

  const loadExercise = (exercise) => {
    setSelectExercises(exercise);
    setEditExercises(null);
    setLessons(null);
    SetlessonDetails(null);
    SetlistmyExercises(null);
    SettrainingDetails(null);
    SetChat_data(null);
    setShowTrainingList(false);
    setTrainingUserData([]);
  };

  const editClicked = (exercise) => {
    setEditExercises(exercise);
    setSelectExercises(null);
    setTrainingUserData([]);
  };

  const exercisesClicked = () => {
    API.getExercises(token["hio-token"])
      .then((resp) => setExercises(resp))
      .catch((error) => console.log());
    SetlessonDetails(null);
    setLessons(null);
    SetlistmyExercises(null);
    SetChat_data(null);
    setselectedChatUserId(null);
    setselectedChatTrainingId(null);
    setShowTrainingList(false);
    setTrainingUserData([]);
  };

  const chatdataClicked = () => {
    API.getChatDataByUser(token["hio-token"])
      .then((resp) => SetChat_data(resp))
      .catch((error) => console.log());
    setselectedChatUserId(null);
    setselectedChatTrainingId(null);
    setEditExercises(null);
    SetlessonDetails(null);
    SettrainingDetails(null);
    setLessons(null);
    SetlistmyExercises(null);
    setExercises(null);
    setShowTrainingList(false);
    setTrainingUserData([]);
  };

  const listAllMyExercises = (user_id) => {
    API.getMyExercises(user_id, token["hio-token"])
      .then((resp) => SetlistmyExercises(resp))
      .catch((error) => console.log());
    SetlessonDetails(null);
    setLessons(null);
    setExercises(null);
    setSelectExercises(null);
    setEditExercises(null);
    SettrainingDetails(null);
    Setsingleuser(null);
    SetChat_data(null);
    setselectedChatUserId(null);
    setselectedChatTrainingId(null);
    setShowTrainingList(false);
    setTrainingUserData([]);
  };

  // Wird verwendet um die Übungen eines user anzuzeigen
  const UserExercises = (user_id) => {
    API.getSingleUser(user_id, token["hio-token"])
      .then((resp) => Setsingleuser(resp))
      .catch((error) => console.log());

    API.getMyExercises(user_id, token["hio-token"])
      .then((resp) => SetuserExercise(resp))
      .catch((error) => console.log());

    SetlessonDetails(null);
    SettrainingDetails(null);
    SetChat_data(null);
    setShowTrainingList(false);
    setTrainingUserData([]);
  };

  const lessonClicked = () => {
    API.getLessons(token["hio-token"])
      .then((resp) => setLessons(resp))
      .catch((error) => console.log());
    SetlessonDetails(null);
    setExercises(null);
    setSelectExercises(null);
    setEditExercises(null);
    SetlistmyExercises(null);
    SettrainingDetails(null);
    SetuserExercise(null);
    Setsingleuser(null);
    SetChat_data(null);
    setselectedChatUserId(null);
    setselectedChatTrainingId(null);
    setShowTrainingList(false);
    setTrainingUserData([]);
  };

  const ShowAllMyTrainings = () => {
    setShowTrainingList(true);
    SetlessonDetails(null);
    setExercises(null);
    setSelectExercises(null);
    setEditExercises(null);
    SetlistmyExercises(null);
    SettrainingDetails(null);
    SetuserExercise(null);
    Setsingleuser(null);
    SetChat_data(null);
    setselectedChatUserId(null);
    setselectedChatTrainingId(null);
    setLessons(null);
    setTrainingUserData([]);
  };

  const getAllTrainingUserData = () => {
    API.getTrainingUserDataView(token["hio-token"])
      .then((resp) => setTrainingUserData(resp))
      .catch((error) => console.log());
    SetlessonDetails(null);
    setExercises(null);
    setSelectExercises(null);
    setEditExercises(null);
    SetlistmyExercises(null);
    SettrainingDetails(null);
    SetuserExercise(null);
    Setsingleuser(null);
    SetChat_data(null);
    setselectedChatUserId(null);
    setselectedChatTrainingId(null);
    setLessons(null);
  };

  //Diese Funktion ersetzt lediglich das Element welches geändert wurde in exercise-form.js
  const updatedExercise = (exercise) => {
    const newExercises = exercises.map((exec) => {
      if (exec.id === exercise.id) {
        return exercise;
      }
      return exec;
    });
    setExercises(newExercises);
  };

  const newExercise = () => {
    setEditExercises({ title: "", description: "", duration: "" });
    setSelectExercises(null);
  };

  const exerciseCreated = (exercise) => {
    const newExercises = [...exercises, exercise];
    setExercises(newExercises);
  };

  const removeClicked = (exercise) => {
    const newExercises = exercises.filter((exec) => {
      if (exec.id === exercise.id) {
        return false;
      }
      return true;
    });
    setExercises(newExercises);
  };

  const logoutUser = () => {
    deleteToken(["hio-token"]);
  };

  if (loading) return <h1>Loading...</h1>;
  if (error) return <h1>Error loading exercises:</h1>; //Für die Anzeige des Fehlers könnte auch dies verwendet werden {error}</h1>

  return (
    <div className="App">
      <header className="px-2 header">
        <Headerbar MyUsersDetail={MyUsersDetail} />
      </header>
      <div>
        <Notification />
        <h1>
          <PublicIP user_id={MyUsersDetail.id} />
        </h1>
      </div>
      <h6>
        <center className="text-primary">
          Early Access Version 1.0 (2023-10-22-20:30)
        </center>
      </h6>
      <div className="layout">
        <UserList
          users={TrainingUserData}
          lessonDetails={lessonDetails}
          UserExercises={UserExercises}
          MyUsersDetail={MyUsersDetail}
        />

        <TrainingList
          showTrainingList={ShowTrainingList}
          OpenTrainingDetail={loadTraining}
        />

        <LessonsList
          lessons={lessons}
          OpenLessonDetail={loadLesson}
          OpenTrainingDetail={loadTraining}
          MyUsersDetail={MyUsersDetail}
          token={token}
          MyTasks={myTasks}
          MyHomework={MyHomework}
        />
        <LessonsDetail
          lessonDetails={lessonDetails}
          UserExercises={UserExercises}
          OpenTrainingDetail={loadTraining}
          MyUsersDetail={MyUsersDetail}
        />
        <TrainingDetail
          trainingDetails={trainingDetails}
          UserExercises={UserExercises}
          OpenLessonDetail={loadLesson}
          MyUsersDetail={MyUsersDetail}
        />

        <ExercisesList
          exercises={exercises}
          exerciseClicked={loadExercise}
          editClicked={editClicked}
          removeClicked={removeClicked}
          newExercise={newExercise}
        />

        <ExerciseDetail
          exercise={selectExercises}
          updateExercise={loadExercise}
        />

        <MyExercises allmyexercises={listmyExercises} />

        <UserDetail
          user={singleuser}
          myexercises={userExercise}
          UserExercises={UserExercises}
          MyTasks={myTasks}
          MyUsersDetail={MyUsersDetail}
        />

        {/* 
        Folgender Code zeigt entweder Chat_data an (Liste aller Chats) sofern kein Chat von einem User gewählt ist
        Ansonsten den direkten Chat
        */}
        <div>
          {selectedChatUserId ? (
            <Chat
              user_id={selectedChatUserId}
              myUser_id={MyUsersDetail.id}
              chatName={chatName}
            />
          ) : selectedChatTrainingId ? (
            <Chat_Training
              training_id={selectedChatTrainingId}
              myUser_id={MyUsersDetail.id}
              chatName={chatName}
            />
          ) : (
            <ChatData
              onUserClick={setselectedChatUserId}
              chatName={setChatName}
              onTrainingClick={setselectedChatTrainingId}
              chat_data={chat_data}
            />
          )}
        </div>
      </div>

      <div className="customMenuBottom">
        <ul className="d-flex align-items-center justify-content-evenly pt-3 ps-0">
          <li className="customMenuIcon">
            <img
              src="/images/Icons/White_Icons/Group 17.png"
              alt="no image"
              onClick={lessonClicked}
            />
          </li>
          <li className="customMenuIcon">
            <a href="#">
              <img
                src="/images/Icons/White_Icons/Group 19.png"
                alt="no image"
                onClick={getAllTrainingUserData}
                // Nicht aktiviert, da meine Exercises neu die Hausaufgaben sind. Diese Funktion hat früher alle meine Exyercises angezeigt, was nicht mehr gebraucht wird
                /*                 onClick={() => listAllMyExercises(MyUsersDetail.id)} // Übergabe der user_id für den eingeloggten user
                 */
              />
            </a>
          </li>
          <li className="customMenuIcon">
            <a href="#">
              <img
                src="/images/Icons/White_Icons/Group 20.png"
                alt="no image"
                onClick={ShowAllMyTrainings}
                /* Mit der folgenden Zeile können alle Übungen aufgerufen werden. Ich lasse den Code hier, ev. brauche ich den noch...*/
                /* onClick={exercisesClicked} */
              />
            </a>
          </li>
          <li className="customMenuIcon">
            <a href="#">
              <img
                src="/images/Icons/White_Icons/Group 94.png"
                alt="no image"
                onClick={chatdataClicked}
              />
            </a>
          </li>
          <li className="customMenuIcon bg-white customMenuIconPlus">
            {/* PLUS BUTTON DIESER CODE MUSS eingeblendet werden für den Button*/
            /*
           <button
              className="btn PlusBtn"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom fs-40 "
            >
              +
            </button> */}

            <div
              className="offcanvas offcanvas-bottom"
              tabIndex="-1"
              id="offcanvasBottom"
              aria-labelledby="offcanvasBottomLabel"
            >
              <div className="offcanvas-body small">
                <div className="px-3">
                  <h1>Hinzufugen</h1>
                  <ul className="Menu text-center mt-5 ps-0">
                    <li className="MenuLink">
                      <a href="#" className="text-dark fw-bold">
                        {" "}
                        <span>+</span> Kurs{" "}
                      </a>
                    </li>
                    <hr />
                    <li className="MenuLink  toggle_MenuBtn">
                      <a href="#" className="text-dark fw-bold">
                        {" "}
                        <span>+</span> Lektion{" "}
                      </a>
                      <div>
                        <ul className="toggleMenu mt-2 ps-0">
                          <li className="MenuSubLink">
                            <a href="#" className="text-darklightgray">
                              {" "}
                              <span>+</span> Anhang{" "}
                            </a>
                          </li>
                          <li className="MenuSubLink mt-1">
                            <a href="#" className="text-darklightgray">
                              {" "}
                              <span>+</span> Notiz{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <hr />
                    <li className="MenuLink">
                      <a href="#" className="text-dark fw-bold">
                        {" "}
                        <span>+</span> Übung{" "}
                      </a>
                    </li>
                    <hr />
                    <li className="MenuLink">
                      <a href="#" className="text-dark fw-bold">
                        {" "}
                        <span>+</span> Hausaufgabe{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
