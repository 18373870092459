import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { requestForToken, onMessageListener } from "../firebase";
import { API } from "../api-service";
import { useCookies } from "react-cookie";

// Firebase Notification Container
const Notification = () => {
  const [token] = useCookies(["hio-token"]);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast(<ToastDisplay />);
  const [FCMToken, setFCMToken] = useState();

  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    requestForToken()
      .then((FCMToken) => {
        setFCMToken(FCMToken);
      })
      .catch((error) => {
        console.log("Fehler beim Abrufen des Tokens:", error);
      });
  }, []);

  useEffect(() => {
    if (FCMToken) {
      API.postFCMToken(FCMToken, token["hio-token"])
        .then((resp) => {})
        .catch((error) => console.log(error));
    }
  }, [FCMToken, token]);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        });
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  return <Toaster />;
};

export default Notification;
