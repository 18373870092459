import React from "react";

function Note(props) {
  if (!props) {
    return null;
  }

  return (
    <div>
      {props.note.length > 0 && (
        <div className="card p-3 bg-lightGray br-12 mb-2">
          {props.note.map((note, index) => (
            <div key={note.id}>
              <p>{note.note}</p>
              {index !== props.note.length - 1 && <hr />}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
export default Note;
