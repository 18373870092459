import React, { useState, useEffect } from "react";
import Exercise from "./exercise.js";
import Homework from "./homework.js";
import { API } from "../api-service";
import { useCookies } from "react-cookie";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TaskList from "./task-list";
import SkillLevel from "./skill-level.js";

function UserDetail(props) {
  const [showModalNewNote, setShowModalNewNote] = useState(false);
  const [token] = useCookies(["hio-token"]);
  const [newExercise, setnewExercise] = useState("");
  const [allExercises, setallExercises] = useState(null);
  const [exercises, setExercises] = useState(props.myexercises);
  const [TasksByUser, setTasksByUser] = useState(null);
  const [MyHomework, setMyHomework] = useState(null);

  const user = props.user;

  useEffect(() => {
    const loadMyHomework = () => {
      if (user && user.id) {
        API.getMyHomework(token["hio-token"], user.id)
          .then((resp) => setMyHomework(resp))
          .catch((error) => console.log(error));
      }
    };

    loadMyHomework();
  }, [user]);

  useEffect(() => {
    setExercises(props.myexercises);
  }, [props.myexercises]);

  const getAllTasksByUser = () => {
    if (user && user.id) {
      API.getTasksByUser(user.id, token["hio-token"])
        .then((resp) => setTasksByUser(resp))
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getAllTasksByUser();
  }, [user]);

  if (!props.user) {
    return null;
  }

  return (
    <div>
      <div className="ps-3 mt-4">
        <div className="AndersonImage mb-2">
          {user.profile.image && (
            <img src={user.profile.image} width="80px" height="80px" alt="" />
          )}
        </div>
        <h1 className="text-white fw-bold serif">
          {user.first_name} {user.last_name}
        </h1>
        <ToastContainer />
      </div>

      <Homework MyHomework={MyHomework} />
      <SkillLevel user_id={user.id} MyUsersDetail={props.MyUsersDetail} />
      {/* Task List Depricated */}
      {/*  <TaskList MyTasks={TasksByUser} user={user} /> */}
    </div>
  );
}

export default UserDetail;
