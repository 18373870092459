import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useCookies } from "react-cookie";

function ExercisesDetails(props) {
  const [highlighted, setHighlighted] = useState(-1);
  const [token] = useCookies(["hio-token"]);

  const exerc = props.exercise;

  const highlightRate = (high) => (evt) => {
    setHighlighted(high);
  };

  const rateClicked = (rate) => (evt) => {
    //fetch(`http://127.0.0.1:8000/api/exercises/${exerc.id}/rate_exercise/`, {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/exercises/${exerc.id}/rate_exercise/`,
      {
        //Hochkomma muss "pactic" sein, damit die Variable übernommen werden kann.
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token["hio-token"]}`,
        },
        body: JSON.stringify({ stars: rate + 1 }),
      }
    )
      .then(() => getDetails()) //Ruft bei Erfolg die API nochmals auf, siehe Funktion unten: getDetails
      .catch((error) => console.log(error));
  };

  const getDetails = () => {
    //fetch(`http://127.0.0.1:8000/api/exercises/${exerc.id}/`, {
    fetch(`${process.env.REACT_APP_API_URL}/api/exercises/${exerc.id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token["hio-token"]}`,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => props.updateExercise(resp))
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      {exerc ? (
        <div className="lessonlist">
          <h1>{exerc.title}</h1>
          <p>{exerc.description}</p>
          <FontAwesomeIcon
            icon={faStar}
            className={exerc.avg_rating > 0 ? "orange" : ""}
          />
          <FontAwesomeIcon
            icon={faStar}
            className={exerc.avg_rating > 1 ? "orange" : ""}
          />
          <FontAwesomeIcon
            icon={faStar}
            className={exerc.avg_rating > 2 ? "orange" : ""}
          />
          <FontAwesomeIcon
            icon={faStar}
            className={exerc.avg_rating > 3 ? "orange" : ""}
          />
          <FontAwesomeIcon
            icon={faStar}
            className={exerc.avg_rating > 4 ? "orange" : ""}
          />
          ({exerc.no_of_ratings})
          <div className="rate-container">
            <h2>Rate it</h2>
            {[...Array(5)].map((e, i) => {
              return (
                <FontAwesomeIcon
                  key={i}
                  icon={faStar}
                  className={highlighted > i - 1 ? "purple" : ""}
                  onMouseEnter={highlightRate(i)}
                  onMouseLeave={highlightRate(-1)}
                  onClick={rateClicked(i)}
                />
              );
            })}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default ExercisesDetails;
