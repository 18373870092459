import React from "react";
import ExerciseLibrary from "./exercise-library.js";

function ExercisesList(props) {
  if (!props.exercises) {
    // Wenn exercises noch leer ist, soll nix zurückgegeben werden
    return null;
    /*  return <div>No lesson selected</div>; */
  }

  return (
    <>
      <div className="d-flex  justify-content-between ">
        <div className="d-flex">
          <h1 className="text-white ps-5 inline-h1">Alle Übungen</h1>
        </div>
      </div>

      <div></div>
      <div className="container-fluid mt-4 mb-5 ">
        <div className="row mb-3">
          <div className="col-12 ">
            <div className="card p-4 mb-3">
              <h2>Übungen</h2>

              <div>
                {props.exercises &&
                  props.exercises.map((exercise) => (
                    <ExerciseLibrary exercise={exercise} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExercisesList;
