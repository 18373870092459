// getTasksByUser

import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { API } from "../api-service";

const ChatMessage = ({ message, isMyMessage, timestamp, isRead }) => {
  // Konvertiere den Timestamp in ein JavaScript Date-Objekt
  const date = new Date(timestamp);

  // Definiere die Optionen für das Datum- und Zeitformat
  const options = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  // Formatieren des Datums und der Uhrzeit
  const formattedTimestamp = date.toLocaleString("de-DE", options);
  return (
    <div style={{ textAlign: isMyMessage ? "right" : "left", margin: "10px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: isMyMessage ? "row-reverse" : "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "inline-block",
            backgroundColor: isMyMessage ? "#DCF8C6" : "#F3F3F3",
            padding: "10px",
            borderRadius: "8px",
            maxWidth: "80%",
          }}
        >
          {message}
        </div>
        {/* {!isMyMessage && ( */}
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: isRead ? "green" : "gray",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        />
        {/*    )} */}
      </div>
      <div style={{ fontSize: "12px", color: "#000", marginTop: "5px" }}>
        {formattedTimestamp}
      </div>
    </div>
  );
};

function Chat(props) {
  const [token] = useCookies(["hio-token"]);
  const [UserChat, setUserChat] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const endeDerSeiteRef = useRef(null);

  const getChatbyUser = (user_id) => {
    if (!user_id) {
      // Wenn user_id nicht vorhanden oder falsy ist, beende die Funktion
      return;
    }

    API.getChatbyUser(props.user_id, token["hio-token"])
      .then((resp) => {
        setUserChat(resp);

        // Setze den isRead-Status für alle Nachrichten, die vom aktuellen Benutzer empfangen wurden, auf true
        resp.forEach((chat) => {
          if (chat.receiver === props.myUser_id && !chat.isRead) {
            API.updateChatReadStatus(chat.id, token["hio-token"]).catch(
              (error) => console.log(error)
            );
          }
        });
      })
      .catch((error) => console.log());
  };

  const sendMessage = () => {
    if (!newMessage) {
      return; // Wenn die Nachricht leer ist, beende die Funktion
    }
    let training = null; //Muss leer sein, da die Antwort an eine Person geht

    API.postNewMessage(
      props.myUser_id,
      props.user_id,
      training,
      newMessage,
      token["hio-token"]
    )
      .then((resp) => {
        // Nachricht erfolgreich gesendet, Chat-Inhalt aktualisieren
        getChatbyUser(props.user_id);
        setNewMessage(""); // Eingabefeld leeren
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getChatbyUser(props.user_id);

    // Aktualisiere den Chat alle 5 Sekunden
    const interval = setInterval(() => {
      getChatbyUser(props.user_id);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [props.user_id]); // user_id als Abhängigkeit hinzufügen

  useEffect(() => {
    /* console.log(UserChat); */
  }, [UserChat]);

  useEffect(() => {
    endeDerSeiteRef.current.scrollIntoView({ behavior: "smooth" });
  }, [UserChat]);

  return (
    <div className="chat-container">
      <div className="chat-sticky-header">
        <h2>{props.chatName}</h2>
      </div>

      <div style={{ paddingBottom: "200px" }}>
        {UserChat && (
          <div>
            {UserChat.map((message) => (
              <ChatMessage
                key={message.id}
                message={message.message}
                isMyMessage={message.sender === props.user_id ? false : true}
                timestamp={message.dc}
                isRead={message.isRead}
              />
            ))}
          </div>
        )}

        <div className="NewMessageChat">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <button onClick={sendMessage}>Senden</button>
        </div>
        <div ref={endeDerSeiteRef} />
      </div>
    </div>
  );
}

export default Chat;
