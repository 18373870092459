import React, { Component } from "react";
import Webcam from "react-webcam";

class VideoRecorder extends Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
    this.mediaRecorder = null;
    this.recordedChunks = [];
    this.videoConstraints = {
      width: 480,
      height: 720,
      facingMode: "environment",
      frameRate: 24,
    };
    this.timerInterval = null;
    this.state = {
      recording: false,
      recordingTime: 0,
      recordedVideo: null,
      playingRecordedVideo: false,
      canvasActive: false,
    };
    this.canvasRef = React.createRef();
    this.drawing = false;
    this.startX = 0;
    this.startY = 0;
    this.endX = 0;
    this.endY = 0;
    this.lines = []; // Speichert die gezeichneten Linien
  }

  handleTouchStart = (event) => {
    event.preventDefault();
    const touches = event.touches[0];
    this.startX =
      touches.clientX - this.canvasRef.current.getBoundingClientRect().left;
    this.startY =
      touches.clientY - this.canvasRef.current.getBoundingClientRect().top;
    this.endX = this.startX;
    this.endY = this.startY;
  };

  handleTouchMove = (event) => {
    event.preventDefault();
    const touches = event.touches[0];
    this.endX =
      touches.clientX - this.canvasRef.current.getBoundingClientRect().left;
    this.endY =
      touches.clientY - this.canvasRef.current.getBoundingClientRect().top;

    // Weitere Zeichenoperationen, wenn benötigt
  };

  handleTouchEnd = () => {
    const context = this.canvasRef.current.getContext("2d");

    context.strokeStyle = "black";
    context.lineWidth = 2;
    context.lineJoin = "round";
    context.lineCap = "round";

    context.beginPath();
    context.moveTo(this.startX, this.startY);
    context.lineTo(this.endX, this.endY);
    context.stroke();

    // Speichern Sie die gezeichnete Linie oder tun Sie, was Sie damit beabsichtigen
    this.lines.push({
      startX: this.startX,
      startY: this.startY,
      endX: this.endX,
      endY: this.endY,
    });

    this.startX = this.endX;
    this.startY = this.endY;
  };

  activateCanvas = () => {
    this.setState({ canvasActive: true });
    const canvasContainer = document.getElementById("canvas-container");
    if (canvasContainer) {
      canvasContainer.style.overflow = "hidden"; // Deaktiviert das Scrollen
    }
  };

  deactivateCanvas = () => {
    this.setState({ canvasActive: false });
    const canvasContainer = document.getElementById("canvas-container");
    if (canvasContainer) {
      canvasContainer.style.overflow = "auto"; // Aktiviert das Scrollen
    }
  };

  handleVideoRecorded = (recordedVideo) => {
    if (this.props.onVideoRecorded) {
      this.props.onVideoRecorded(recordedVideo);
    }
  };

  toggleCanvas = () => {
    if (this.canvasVisible) {
      this.deactivateCanvas(); // Canvas deaktivieren
    } else {
      this.activateCanvas(); // Canvas aktivieren
    }
    this.canvasVisible = !this.canvasVisible;
    this.forceUpdate(); // Rendern erneut, um das Canvas zu aktualisieren
  };

  startRecording = () => {
    /* console.log("Starting recording..."); */
    this.mediaRecorder = new MediaRecorder(this.webcamRef.current.stream);

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        this.recordedChunks.push(event.data);
      }
    };

    this.mediaRecorder.onstop = () => {
      clearInterval(this.timerInterval);
      const recordedBlob = new Blob(this.recordedChunks, {
        type: "video/webm",
      });

      this.setState({
        recordedVideo: URL.createObjectURL(recordedBlob),
      });

      // Übergabe des aufgenommenen Videos an die übergeordnete Komponente
      this.handleVideoRecorded(URL.createObjectURL(recordedBlob));
    };

    this.mediaRecorder.start();

    // Start the recording timer
    this.setState({ recording: true });
    this.timerInterval = setInterval(() => {
      this.setState((prevState) => ({
        recordingTime: prevState.recordingTime + 1,
      }));
    }, 1000);
  };

  stopRecording = () => {
    /* console.log("Stopping recording..."); */
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
    // Stop the recording timer
    clearInterval(this.timerInterval);
    this.setState({ recording: false });
  };

  playRecordedVideo = () => {
    this.setState({ playingRecordedVideo: true });
  };

  handleMouseDown = (event) => {
    this.drawing = true;
    this.startX = event.nativeEvent.offsetX;
    this.startY = event.nativeEvent.offsetY;
  };

  handleMouseUp = () => {
    if (this.drawing) {
      const currentX = this.endX;
      const currentY = this.endY;

      this.lines.push({
        startX: this.startX,
        startY: this.startY,
        endX: currentX,
        endY: currentY,
      });
    }
    this.drawing = false;
  };

  handleMouseMove = (event) => {
    if (!this.drawing) return;
    const context = this.canvasRef.current.getContext("2d");

    context.clearRect(
      0,
      0,
      this.canvasRef.current.width,
      this.canvasRef.current.height
    );

    context.strokeStyle = "black";
    context.lineWidth = 2;
    context.lineJoin = "round";
    context.lineCap = "round";

    const currentX = event.nativeEvent.offsetX;
    const currentY = event.nativeEvent.offsetY;

    for (const line of this.lines) {
      context.beginPath();
      context.moveTo(line.startX, line.startY);
      context.lineTo(line.endX, line.endY);
      context.stroke();
    }

    context.beginPath();
    context.moveTo(this.startX, this.startY);
    context.lineTo(currentX, currentY);
    context.stroke();

    this.endX = currentX;
    this.endY = currentY;
  };

  testTouchOnCanvas = () => {
    if ("ontouchstart" in window || navigator.maxTouchPoints) {
      // Das Gerät unterstützt Touch-Ereignisse
      alert("Touch-Ereignisse werden unterstützt.");
    } else {
      // Das Gerät unterstützt keine Touch-Ereignisse
      alert("Touch-Ereignisse werden nicht unterstützt.");
    }
  };

  render() {
    return (
      <div>
        {this.state.recordedVideo ? (
          <div>
            <video controls autoPlay={this.state.playingRecordedVideo}>
              <source src={this.state.recordedVideo} type="video/webm" />
            </video>
            <br />
            <button className="blue-button" onClick={this.playRecordedVideo}>
              Play Recorded Video
            </button>
          </div>
        ) : (
          <div>
            <Webcam
              audio={false}
              videoConstraints={this.videoConstraints}
              ref={this.webcamRef}
            />
            <p>Recording Time: {this.state.recordingTime} seconds</p>
            {this.state.recording ? (
              <button className="blue-button" onClick={this.stopRecording}>
                Stop Recording
              </button>
            ) : (
              <button className="blue-button" onClick={this.startRecording}>
                Start Recording
              </button>
            )}
          </div>
        )}
        <br></br>
        <button className="blue-button" onClick={this.toggleCanvas}>
          {this.canvasVisible ? "Hide Canvas" : "Show Canvas"}
        </button>

        {this.canvasVisible && (
          <div id="canvas-container">
            {this.canvasVisible && (
              <canvas
                ref={this.canvasRef}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: 500,
                  width: 500,
                  pointerEvents: "auto",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  touchAction: "none",
                }}
                width={this.videoConstraints.width}
                height={this.videoConstraints.height}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
                onTouchStart={this.handleTouchStart} // Touch-Ereignisse hinzugefügt
                onTouchMove={this.handleTouchMove} // Touch-Ereignisse hinzugefügt
                onTouchEnd={this.handleTouchEnd} // Touch-Ereignisse hinzugefügt
                /*                 onClick={this.testTouchOnCanvas} // Fügen Sie dieses Attribut hinzu
                 */
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default VideoRecorder;
