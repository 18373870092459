// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCKR09s9puGEddGsJC0TubR5f24ETyakTk",
  authDomain: "hio-training.firebaseapp.com",
  projectId: "hio-training",
  storageBucket: "hio-training.appspot.com",
  messagingSenderId: "918381588470",
  appId: "1:918381588470:web:484c398f2fb62305e821d7",
  measurementId: "G-66ZWWWTKH8",
};

// Initialize Firebase
initializeApp(firebaseConfig);
const messaging = getMessaging();

//const analytics = getAnalytics(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BOgOT9qPweUYDdi_Y8omzTezOoxr1a6n9dE-vt3dPr_wga8pghGXJgGypD9uekSr-6ysdrvH5bPzaOuNGzy1fh8",
    });

    if (currentToken) {
      // Folgende Zeile gibt den Token in der Console aus
      // console.log("current token for client: ", currentToken);
      // Führen Sie weitere erforderliche Aktionen mit dem Token aus
    } else {
      // Zeigen Sie die Berechtigungsanfrage-Benutzeroberfläche an
      console.log(
        "Kein Registrierungstoken verfügbar. Fordern Sie die Berechtigung zur Generierung eines Tokens an."
      );
    }

    return currentToken;
  } catch (err) {
    console.log("Beim Abrufen des Tokens ist ein Fehler aufgetreten. ", err);
    return null;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
