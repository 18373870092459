import React from "react";
import { API } from "../api-service";
import { useCookies } from "react-cookie";
import { useState } from "react";
import Person from "./person";

function UserList(props) {
  const [token] = useCookies(["hio-token"]);

  if (!props || (props.users && props.users.length === 0)) {
    return null;
  }

  return (
    <div className="wrapper">
      <div className="card p-4 mb-3">
        <div className="d-flex justify-content-between">
          <h4 className="fw-bold">Schüler & Trainer</h4>
        </div>
        <div>
          {props.users.users &&
            props.users.users.map((user) => (
              <Person
                key={user.id}
                user={user}
                isTrainer={props.MyUsersDetail.is_trainer}
                UserExercises={props.UserExercises}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default UserList;
