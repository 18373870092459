import React from "react";
import Note from "./note";
import TaskList from "./task-list";
import Homework from "./homework";

function LessonsList(props) {
  //öffnet eine einzelne Lektion
  const OpenLessonDetail = (lesson) => (evt) => {
    props.OpenLessonDetail(lesson);
  };

  //öffnet eine einzelnes Training
  function OpenTrainingDetail(training_id) {
    props.OpenTrainingDetail(training_id);
  }

  if (!props.lessons) {
    return null;
    /* return <div>No Training selected</div>; */
  }

  return (
    <div>
      <div className="d-flex  justify-content-between ">
        <div className="d-flex">
          <h1 className="text-white ps-5 inline-h1">
            Hoi,{" "}
            <span className="serif ms-2 fw-bold inline-h2">
              {props.MyUsersDetail && props.MyUsersDetail.first_name}
            </span>
          </h1>
        </div>
      </div>

      <Homework MyHomework={props.MyHomework} />
      {/* Die alte Task Liste - Depricated */}
      {/* <TaskList MyTasks={props.MyTasks} user={props.MyUsersDetail} /> */}

      <div className="d-flex align-items-center justify-content-between px-5">
        <i className="fa-solid fa-arrow-left text-white"></i>
        <span className="text-white">Lektionen</span>
        <i className="fa-solid fa-arrow-right text-white me-2"></i>
      </div>

      {props.lessons &&
        props.lessons
          .filter((lesson) => {
            const date = new Date(lesson.date);
            const today = new Date();
            return date > today;
          })
          .map((lesson) => {
            const date = new Date(lesson.date);
            const time = `${date.getUTCHours()}:${date
              .getUTCMinutes()
              .toString()
              .padStart(2, "0")}`;
            const formattedDate = date.toLocaleDateString("de-DE", {
              day: "numeric",
              month: "long",
              year: "numeric",
            });

            return (
              <div key={lesson.id} className="pt-2 pb-5">
                <div className="container-fluid">
                  <div className="container-fluid mt-2">
                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="card p-4 toggleWrap">
                          <div className="d-flex align-items-center justify-content-between">
                            <h3>
                              {lesson.lesson_position} {lesson.title}
                            </h3>
                            <div className="RArrow">
                              <a
                                href="#"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src="/images/Icons/Arrow_right_big.png"
                                  width="60%"
                                  height="auto"
                                  onClick={OpenLessonDetail(lesson)}
                                />
                              </a>
                            </div>
                          </div>

                          <a
                            href="#"
                            className="underline text-dark"
                            onClick={(event) => {
                              event.preventDefault();
                              OpenTrainingDetail(lesson.training_id);
                            }}
                          >
                            {lesson.training_title}
                          </a>

                          <div className="row mt-3">
                            <div className="col-6">
                              <div className="card p-2 d-flex flex-column ">
                                <img
                                  src="/images/Icons/Ebene_14.png"
                                  width="20px"
                                  height="20px"
                                  alt=""
                                />
                                <span>{formattedDate}</span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="card p-2 d-flex flex-column">
                                <img
                                  src="/images/Icons/Ebene_13.png"
                                  width="20px"
                                  height="20px"
                                  alt=""
                                />
                                <span>{time}</span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-6">
                              <div className="card p-2 d-flex flex-column ">
                                <img
                                  src="/images/Icons/Ebene_12.png"
                                  width="20px"
                                  height="20px"
                                  alt=""
                                />
                                <span>{lesson.meeting_point}</span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="card p-2 d-flex flex-column">
                                <img
                                  src="/images/Icons/Ebene_13.png"
                                  width="20px"
                                  height="20px"
                                  alt=""
                                />
                                <span>Duration: {lesson.duration}</span>
                              </div>
                            </div>
                          </div>

                          <Note note={lesson.lesson_notes} />

                          <div>
                            <p className="card-text">{lesson.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
}

export default LessonsList;
