import React from "react";
import { useCookies } from "react-cookie";

function Headerbar(props) {
  const [token, setToken, deleteToken] = useCookies(["hio-token"]);

  const logoutUser = () => {
    deleteToken(["hio-token"]);
  };

  const profileImage =
    props.MyUsersDetail && props.MyUsersDetail.profile
      ? props.MyUsersDetail.profile.image
      : null;

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        {/*  <div className="searchForm compressView d-flex ps-2">
          <img
            src="./images/Icons/Ebene_17.png"
            width="20px"
            height="20px"
            alt="search"
            className="searchImage"
          />
          <input type="search" id="form1" className="form-control" />
          <div className="SearchArrow">
            <i className="fa-solid fa-angle-right text-white"></i>
          </div>
        </div> */}
        {/*   <div className="d-flex ">
          <div className="flag ">
            <a href="#">
              <img src="../images/Icons/notification-golf.png" alt="no Image" />
            </a>
          </div>
        </div> */}
        <div className="userProfile ListImage">
          <button
            className="btn "
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasLeft"
            aria-controls="offcanvasLeft "
          >
            {profileImage && (
              <img src={profileImage} width="50px" height="50px" alt="" />
            )}
          </button>
          <div
            className="offcanvas offcanvas-left"
            tabIndex="-1"
            id="offcanvasLeft"
            aria-labelledby="offcanvasLeftLabel"
          >
            <div className="offcanvas-header header">
              <div className="d-flex justify-content-between w-100">
                {/*  <div className="searchForm compressView2 d-flex ps-2">
                  <img
                    src="./images/Icons/Ebene_17.png"
                    width="20px"
                    height="20px"
                    alt="search"
                    className="searchImage2"
                  />
                  <input type="search" id="form3" className="form-control" />
                  <div className="SearchArrow">
                    <i className="fa-solid fa-angle-right text-white"></i>
                  </div>
                </div> */}
                <div className="d-flex ">
                  {/* <div className="flag2">
                    <a href="Nachrichten.html">
                      <img
                        src="./images/Icons/notification-golf.png"
                        alt="no Image"
                      />
                    </a>
                  </div> */}
                  <div className="userProfile2">
                    <button
                      className="btn "
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasLeft"
                      aria-controls="offcanvasLeft "
                    >
                      <img
                        src={profileImage || "/images/no_profile.png"}
                        width="50px"
                        height="50px"
                        alt=""
                      />
                    </button>

                    <div
                      className="offcanvas offcanvas-left"
                      tabIndex="-1"
                      id="offcanvasLeft"
                      aria-labelledby="offcanvasLeftLabel"
                    >
                      {/* <div className="offcanvas-header header">
                        <div className="d-flex justify-content-between w-100">
                          <div className="searchForm compressView2 d-flex ps-2">
                            <img
                              src="./images/Icons/Ebene_17.png"
                              width="20px"
                              height="20px"
                              alt="search"
                              className="searchImage2"
                            />
                            <input
                              type="search"
                              id="form3"
                              className="form-control"
                            />
                            <div className="SearchArrow">
                              <i className="fa-solid fa-angle-right text-white"></i>
                            </div>
                          </div>
                          <div className="d-flex ">
                            <div className="flag2">
                              <a href="Nachrichten.html">
                                <img
                                  src="./images/Icons/notification-golf.png"
                                  alt="no Image"
                                />
                              </a>
                            </div>
                            <div className="userProfile2">
                              <img
                                src="./images/Icons/profile.png"
                                width="40px"
                                height="40px"
                                alt=""
                              />
                              <span className="dot">.</span>
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn-close d-none"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div> */}
                      <div className="offcanvas-body small">
                        <div className="px-3">
                          <h1>Einstellungen</h1>
                          <ul className="Menu text-center mt-5 ps-0">
                            <li className="MenuLink mb-4">
                              {" "}
                              <a href="#" className="text-darklight">
                                Account
                              </a>
                            </li>
                            <li className="MenuLink mb-4">
                              <a href="#" className="text-darklight">
                                Benachrichtigungen
                              </a>
                            </li>
                            <li className="MenuLink">
                              <a
                                className="text-darklight"
                                onClick={logoutUser}
                              >
                                Logout
                              </a>
                            </li>
                          </ul>
                        </div>

                        {/*   <div className="customMenuBottomOff ">
                          <ul className="d-flex align-items-center justify-content-evenly pt-3 ps-0">
                            <li className="customMenuIcon">
                              <a href="Dashboard.html">
                                <img
                                  src="./images/Icons/White_Icons/Group 17.png"
                                  alt="no image"
                                />
                              </a>
                            </li>
                            <li className="customMenuIcon">
                              <a href="#">
                                <img
                                  src="./images/Icons/White_Icons/Group 19.png"
                                  alt="no image"
                                />
                              </a>
                            </li>
                            <li className="customMenuIcon">
                              <a href="#">
                                <img
                                  src="./images/Icons/White_Icons/Group 20.png"
                                  alt="no image"
                                />
                              </a>
                            </li>
                            <li className="customMenuIcon">
                              <a href="#">
                                <img
                                  src="./images/Icons/White_Icons/Group 94.png"
                                  alt="no image"
                                />
                              </a>
                            </li>
                            <li className="customMenuIcon bg-transparent customMenuIconPlus">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"
                              >
                                <i className="fa-solid fa-xmark text-white"></i>
                              </button>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn-close d-none"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body small">
              <div className="px-3">
                <h1>Einstellungen</h1>
                <ul className="Menu text-center mt-5 ps-0">
                  <li className="MenuLink mb-4">
                    {" "}
                    <a href="#" className="text-darklight">
                      Account
                    </a>
                  </li>
                  <li className="MenuLink mb-4">
                    <a href="#" className="text-darklight">
                      Benachrichtigungen
                    </a>
                  </li>
                  <li className="MenuLink">
                    <a className="text-darklight" onClick={logoutUser}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>

              {/* <div className="customMenuBottomOff ">
                <ul className="d-flex align-items-center justify-content-evenly pt-3 ps-0">
                  <li className="customMenuIcon">
                    <a href="Dashboard.html">
                      <img
                        src="./images/Icons/White_Icons/Group 17.png"
                        alt="no image"
                      />
                    </a>
                  </li>
                  <li className="customMenuIcon">
                    <a href="#">
                      <img
                        src="./images/Icons/White_Icons/Group 19.png"
                        alt="no image"
                      />
                    </a>
                  </li>
                  <li className="customMenuIcon">
                    <a href="#">
                      <img
                        src="./images/Icons/White_Icons/Group 20.png"
                        alt="no image"
                      />
                    </a>
                  </li>
                  <li className="customMenuIcon">
                    <a href="Lektion2.html">
                      <img
                        src="./images/Icons/White_Icons/Group 94.png"
                        alt="no image"
                      />
                    </a>
                  </li>
                  <li className="customMenuIcon bg-transparent customMenuIconPlus">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <i className="fa-solid fa-xmark text-white"></i>
                    </button>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Headerbar;
