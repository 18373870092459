import React, { useState, useEffect } from "react";
import UserDetail from "./user-detail"; // Wenn Sie das nicht verwenden, sollten Sie es entfernen.
import { useCookies } from "react-cookie";
import { API } from "../api-service";

const Dashboard = () => {
  const [token] = useCookies(["hio-token"]);
  const [MyUsersDetail, SetMyUsersDetails] = useState({}); // Anfangswert als leeres Objekt.

  useEffect(() => {
    // Diese Funktion lädt die Benutzerdetails, wenn die Komponente geladen wird.
    API.getMyUserDetail(token["hio-token"])
      .then((resp) => SetMyUsersDetails(resp))
      .catch((error) => console.log(error));
  }, [token]); // Abhängigkeiten für useEffect

  return (
    <div>
      <h1 className="text-white ps-5 inline-h1">Dashboard</h1>
      <div className="d-flex  justify-content-between ">
        <div className="d-flex">
          <h1 className="text-white ps-5 inline-h1">
            Hoi,{" "}
            <span className="serif ms-2 fw-bold inline-h2">
              {MyUsersDetail.first_name} (
              {MyUsersDetail.profile && MyUsersDetail.profile.golfcourse
                ? MyUsersDetail.profile.golfcourse.name
                : ""}
              )
            </span>
          </h1>
        </div>
      </div>
      {MyUsersDetail.profile && MyUsersDetail.profile.golfcourseManager ? (
        <div>
          <h2>Hi</h2>
        </div>
      ) : null}
    </div>
  );
};

export default Dashboard;
