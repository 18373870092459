import React, { useState } from "react";
import ReactPlayer from "react-player";
import ReactMarkdown from "react-markdown";

//Diese Komponente wird verwendet, um alle Exercises in einer Liste mit allen Goals und Executions aufzuzeigen.
//Im Gegensatz zur Exercise Komponente, die nur jeweils die Exercise anzeigt, die auch in einer Letktion hinzugefügt wurde
//Bei der Komp. Exercises wird nur ein Goal und ein Execution dargestellt.

function ExerciseLibrary(props) {
  const [showContent, setShowContent] = useState(false); // Zustand, der angibt, ob der Inhalt angezeigt wird oder nicht

  if (!props) {
    return null;
  }

  /*  console.log(props); */

  let imageUrl = props.exercise.image;
  if (imageUrl && imageUrl.includes("/media")) {
    imageUrl = "/media" + imageUrl.split("/media")[1];
  }

  const handleSelectExercise = (exercise_id) => {
    props.getSelectedExercise(exercise_id);
  };

  return (
    <div key={"execomp" + props.exercise.id}>
      <div key={"exe" + props.exercise.id}>
        <div className="card p-2 pt-0 b-2 mb-2 ">
          <div className="d-flex justify-content-between ubungenCardHeader">
            <h5 className="fw-normal pt-2">
              {" "}
              <span className="title fw-bold fs-20 me-2">
                {props.exercise.id}
              </span>{" "}
              {props.exercise.title}
            </h5>
          </div>
          <div className="space-bottom">
            <div className="d-flex align-items-center">
              {props.exercise.tags &&
                props.exercise.tags.map((tag) => (
                  <span key={"tag" + tag.id}>
                    <button className="btn br-30 bg-primary">
                      {tag.title}
                    </button>
                  </span>
                ))}
            </div>
          </div>
          <div className="d-flex justify-content-between textToHideWrapper">
            <div className="b-1 py-1 px-2 br-12 mb-2 d-flex align-items-center w-fitContent minWidth-47">
              <img
                src="/images/Icons/Ebene_13.png"
                width="15px"
                height="15px"
                alt=""
                className="me-2"
              />
              <span>
                <b>Dauer:</b> {props.exercise.duration} Min -{" "}
                <b>Vorbereitung:</b> {props.exercise.preptime} Min
              </span>
            </div>
          </div>
          <div>
            {props.showselect && (
              <button onClick={() => handleSelectExercise(props.exercise.id)}>
                Add Exercise to User
              </button>
            )}
          </div>
          <div style={{ display: showContent ? "block" : "none" }}>
            <div>
              <span>
                <div className="col-12 mt-2">
                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="15px"
                      height="15px"
                      alt=""
                    />
                    <b>Grund</b>
                    <ReactMarkdown>{props.exercise.description}</ReactMarkdown>
                  </div>
                </div>
              </span>

              <span>
                <div className="col-12 mt-2">
                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="15px"
                      height="15px"
                      alt=""
                    />
                    <b>Übungsaufbau</b>
                    <ReactMarkdown>{props.exercise.setup}</ReactMarkdown>
                  </div>
                </div>
              </span>
              <div className="d-flex align-items-center">
                {props.exercise.equipments &&
                  props.exercise.equipments.map((equipment) => (
                    <div key={"equ" + equipment.id}>
                      <button className="btn br-30 bg-primary">
                        {equipment.title}
                      </button>
                    </div>
                  ))}
              </div>
              <span>
                <div className="col-12 mt-2">
                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="15px"
                      height="15px"
                      alt=""
                    />
                    <b>Ziel</b>

                    {props.exercise.goal &&
                      props.exercise.goal.map((goal) => (
                        <span key={"goal" + goal.id}>
                          <div className="b-1 py-1 px-2 br-12 mb-2 ">
                            <ReactMarkdown>{goal.description}</ReactMarkdown>
                          </div>
                        </span>
                      ))}
                  </div>
                </div>
              </span>
              <span>
                <div className="col-12 mt-2">
                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="15px"
                      height="15px"
                      alt=""
                    />
                    <b>Aufgabe</b>
                    {props.exercise.execution &&
                      props.exercise.execution.map((execution) => (
                        <span key={"execution" + execution.id}>
                          <div className="b-1 py-1 px-2 br-12 mb-2 ">
                            <ReactMarkdown>
                              {execution.description}
                            </ReactMarkdown>
                          </div>
                        </span>
                      ))}
                  </div>
                </div>
              </span>
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt={props.exercise.title}
                  className="exercise-img"
                />
              )}
              {props.exercise.video_url && (
                <ReactPlayer
                  url={props.exercise.video_url}
                  width="100%"
                  className="exercise-vid"
                />
              )}
            </div>

            <hr></hr>
            <div className="d-flex justify-content-between textToHideWrapper">
              <div className="b-1 py-1 px-2 br-12 mb-2 d-flex align-items-center w-fitContent minWidth-47">
                <img
                  src="/images/Icons/Ebene_13.png"
                  width="15px"
                  height="15px"
                  alt=""
                  className="me-2"
                />

                <span>Stars: {props.exercise.no_of_ratings}</span>
              </div>
              <div className="b-1 py-1 px-2 br-12 mb-2 d-flex align-items-center w-fitContent minWidth-47">
                <img
                  src="/images/Icons/Ebene_13.png"
                  width="15px"
                  height="15px"
                  alt=""
                  className="me-2"
                />
                <span>Rating: {props.exercise.avg_rating}</span>
              </div>
            </div>
          </div>
          <div className="text-center d-flex align-items-center justify-content-center">
            <div
              className="toggle_btn mt-2 d-flex align-items-center"
              onClick={() => setShowContent(!showContent)} // Ändern Sie den Zustand, wenn auf den Button geklickt wird
            >
              <span className="toggle_text">
                {showContent ? "weniger" : "mehr"}
              </span>
              <span className="arrow ms-2 mt-1">
                <i
                  className={`fas fa-angle-${showContent ? "up" : "down"}`}
                ></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ExerciseLibrary;
