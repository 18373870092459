import React from "react";
import { API } from "../api-service";
import { useCookies } from "react-cookie";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import Exercise from "./exercise.js";
import Person from "./person";
import Note from "./note";

function LessonsDetail(props) {
  const [token] = useCookies(["hio-token"]);
  const [newNote, setNewNote] = useState("");
  const [showModalNewNote, setShowModalNewNote] = useState(false);

  if (!props.lessonDetails) {
    return null;
    /*  return <div>No lesson selected</div>; */
  }

  const lessonDetails = props.lessonDetails;
  const MyUsersDetail = props.MyUsersDetail;

  /*   console.log(lessonDetails.users); */

  const handleOpenModalNewNote = () => {
    setShowModalNewNote(true);
  };

  const handleCloseModalNewNote = () => {
    setShowModalNewNote(false);
  };

  const createNoteClicked = (note, lesson_id) => {
    API.createLessonNote(lesson_id, note, token["hio-token"])
      .then((resp) => {
        setShowModalNewNote(false); // add this line to close the modal
      })
      .catch((error) => console.log(error));
  };

  //öffnet eine einzelnes Training
  function OpenTrainingDetail(training_id) {
    props.OpenTrainingDetail(training_id);
  }

  const date = new Date(lessonDetails.date);
  const time = `${date.getUTCHours()}:${date
    .getUTCMinutes()
    .toString()
    .padStart(2, "0")}`;
  const formattedDate = date.toLocaleDateString("de-DE", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="wrapper">
      <div className="pt-2 pb-5 lektion2Container mb-5">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className=" d-flex align-items-center ps-5">
              <span className="vertDivider me-2"></span>{" "}
              <a
                href="#"
                className="text-light"
                onClick={(event) => {
                  event.preventDefault();
                  OpenTrainingDetail(lessonDetails.training_id);
                }}
              >
                {lessonDetails.training_title}
              </a>{" "}
              <span className="vertDivider mx-2"></span>
              <a href="#" className="text-white fw-semibold ">
                {lessonDetails.title}{" "}
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div className="ps-5">
              <h1 className="text-white fw-bold serif">
                {lessonDetails.title}
              </h1>
              <h6 className="text-light fw-light mtminus-5">{formattedDate}</h6>
              <div className="d-flex align-items-center">
                {lessonDetails.tags &&
                  lessonDetails.tags.map((tag) => (
                    <span key={tag}>
                      <button className="btn br-30 bg-primary">{tag}</button>
                    </span>
                  ))}
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4 mb-5 ">
            <div className="row mb-3">
              <div className="col-12 ">
                <div className="card p-4">
                  <h2>Übungen</h2>

                  <div>
                    {lessonDetails.exercises &&
                      lessonDetails.exercises.map((exercise) => (
                        <Exercise
                          exercise={exercise}
                          lessonDetails={lessonDetails}
                          MyUsersDetail={MyUsersDetail}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-4 mb-3">
              <div className="d-flex justify-content-between">
                <h4 className="fw-bold">Schüler</h4>
              </div>
              <div>
                {lessonDetails.users &&
                  lessonDetails.users.map((user) => (
                    <Person
                      user={user}
                      lesson_id={lessonDetails.id}
                      UserExercises={props.UserExercises}
                      isTrainer={props.MyUsersDetail.is_trainer}
                    />
                  ))}
              </div>
            </div>
            <div className="card p-4 mb-3">
              <div className="d-flex justify-content-between">
                <h4 className="fw-bold">Trainer</h4>
              </div>

              <div>
                {lessonDetails.trainers &&
                  lessonDetails.trainers.map((trainer) => (
                    <Person user={trainer} />
                  ))}
              </div>
            </div>
            <div className="card p-4 mb-3">
              <h4 className="fw-bold">Notizen</h4>
              {/* ANHàNGE wieder einblenden */
              /* <h6>Anhange</h6>
              <div className="AnhangBlock p-3 br-12 mb-3">
                <p>Lorem, ipsum dolor sit amet consectetur elit. Qui, </p>
                <div className="plusCircle">+</div>
              </div>
              <h6>Notizen</h6> */}

              <Note note={lessonDetails.lesson_notes} />

              <div className="plusCircle" onClick={handleOpenModalNewNote}>
                +
              </div>
              <Modal show={showModalNewNote} onHide={handleCloseModalNewNote}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3>Add Note</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <br></br>
                  <textarea
                    className="NoteInput"
                    id="newNote"
                    rows="8"
                    /* placeholder="add new note" */
                    /* value={newNote} */
                    onChange={(evt) => setNewNote(evt.target.value)}
                  />
                  <br></br>
                </Modal.Body>
                <Modal.Footer>
                  {" "}
                  <button
                    onClick={() => createNoteClicked(newNote, lessonDetails.id)}
                  >
                    Create Note
                  </button>
                  <button onClick={handleCloseModalNewNote}>Close</button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="card p-4 mb-3">
              <h4 className="fw-bold">Lektion</h4>
              <p>{lessonDetails.description}</p>
              <div className="row mt-3">
                {/* NIVEAU UND TEILNEHMER */
                /* <div className="col-6">
                  <div className="card p-2 d-flex flex-column">
                    <h6 className="fw-bold mb-0">Niveau</h6>
                    <span>???</span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="card p-2 d-flex flex-column">
                    <h6 className="fw-bold mb-0">Telinehmar</h6>
                    <span>???</span>
                  </div>
                </div> */}
                <div className="col-6 mt-2">
                  <div className="card p-2 d-flex flex-column">
                    <img
                      src="/images/Icons/Ebene_14.png"
                      width="20px"
                      height="20px"
                      alt=""
                    />
                    <span>{formattedDate}</span>
                  </div>
                </div>
                <div className="col-6 mt-2">
                  <div className="card p-2 d-flex flex-column">
                    <img
                      src="/images/Icons/Ebene_13.png"
                      width="20px"
                      height="20px"
                      alt=""
                    />
                    <span>{time}</span>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="card p-2 d-flex flex-column">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="20px"
                      height="20px"
                      alt=""
                    />
                    <span>{lessonDetails.meeting_point}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LessonsDetail;
