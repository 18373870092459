import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import ReactMarkdown from "react-markdown";
import { Modal } from "react-bootstrap";
import CaptureImage from "./CaptureImage";
import { API } from "../api-service";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import VideoRecorder from "./VideoRecorder.js";

function Exercise(props) {
  const [showModalNewTask, setShowModalNewTask] = useState(false);
  useEffect(() => {
    if (showModalNewTask) {
      getKeywordsForTags();
    }
  }, [showModalNewTask]);

  //Diesen Code braucht es um zu prüfen, ob der usre ein Trainer ist oder nicht
  // Der UseEffect braucht es, damit die Funktion nur einmal ausgeführt wird.
  const [userClickHandled, setUserClickHandled] = useState(false);
  useEffect(() => {
    // Überprüfen, ob handleUserClick noch nicht aufgerufen wurde
    if (!userClickHandled && !props.MyUsersDetail.is_trainer) {
      handleUserClick(props.MyUsersDetail.id);
      // Setze die Zustandsvariable, um zukünftige Aufrufe zu verhindern
      setUserClickHandled(true);
    }
  }, [
    props.MyUsersDetail.is_trainer,
    props.MyUsersDetail.id,
    userClickHandled,
  ]);

  // Zustand für die anzuzeigenden Schlagworte
  const [displayedKeywords, setDisplayedKeywords] = useState(25);
  // Zustand, um den "mehr" Button anzuzeigen/auszublenden
  const [showMore, setShowMore] = useState(true);

  const [showContent, setShowContent] = useState(false); // Zustand, der angibt, ob der Inhalt angezeigt wird oder nicht
  const [showModal, setShowModal] = useState(false);

  const [newTask, setNewTask] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [taskDescriptionStates, setTaskDescriptionStates] = useState({});
  const [showCaptureImage, setShowCaptureImage] = useState(false);
  const [token] = useCookies(["hio-token"]);

  /* Speichert die ID des gewählten Goals einer Übung zum Speichern einer neuen Hausaufgabe */
  const [selectedGoalId, setSelectedGoalId] = useState(props.exercise.goal.id);
  /* Dasselbe für Execution */
  const [selectedExecutionId, setselectedExecutionId] = useState(
    props.exercise.execution.id
  );
  const [selectedUserId, setselectedUserId] = useState(null);

  const [note, setNote] = useState(""); // Hier wird der Zustand für "note" definiert

  const [activeTab, setActiveTab] = useState("note"); // Der aktive Tab

  const [Keywords, setKeywords] = useState(null); // Speichert die Keywords für die gewählte übung
  const [TagArray, setTagArray] = useState([]); //Array mit allen tags für diese Exercise

  // Eine Liste mit allen Keywords die geklickt wurden
  const [selectedKeywords, setSelectedKeywords] = useState([]);

  //Eine Liste der Tasks die soeben erstellt wurden (wird nach Speichern einer Hausaufgabe wieder geleert)
  const [createdTasks, setcreatedTasks] = useState([]);

  // Speichert den Inhalt eines ganzen Tasks
  const [createdTaskContent, setCreatedTaskContent] = useState([]);

  // Variable um das aufgenommen Video zu speichern aus der VideoRecorder Komponente
  const [recordedVideo, setRecordedVideo] = useState(null);

  if (!props) {
    return null;
  }

  const handleVideoRecorded = (recordedVideoURL) => {
    // Lade das aufgezeichnete Video herunter und konvertiere es in einen Blob
    fetch(recordedVideoURL)
      .then((response) => response.blob())
      .then((blob) => {
        // Speichere das Blob im State
        setRecordedVideo(blob);
      })
      .catch((error) => {
        console.error("Fehler beim Konvertieren des Videos in Blob:", error);
      });
  };
  let imageUrl = props.exercise.exercise.image;
  if (imageUrl && imageUrl.includes("/media")) {
    imageUrl = "/media" + imageUrl.split("/media")[1];
  }

  const handleSelectExercise = (exercise_id) => {
    props.getSelectedExercise(exercise_id);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleOpenModalNewTask = () => {
    setSelectedKeywords([]);
    setShowModalNewTask(true);
  };

  const handleCloseModalNewTask = () => {
    setShowModalNewTask(false);
  };

  // Funktion, um mehr Schlagworte anzuzeigen
  const showMoreKeywords = () => {
    setDisplayedKeywords(displayedKeywords + 25);
  };

  const createTaskClicked = (user_id, task_description, imageFile, video) => {
    //Speichert den Task
    API.createTask(
      user_id,
      task_description,
      imageFile,
      video,
      token["hio-token"]
    )
      .then((resp) => {
        // Hier wird die gesamte Aufgabe gespeichert
        const createdTask = resp;
        setCreatedTaskContent((prevCreatedTaskContent) => [
          ...prevCreatedTaskContent,
          createdTask,
        ]);

        // Hier kannst du die ID der erstellten Aufgabe aus der Antwort extrahieren
        const createdTaskID = resp.id;
        setcreatedTasks((prevCreatedTasks) => [
          ...prevCreatedTasks,
          createdTaskID,
        ]);

        /* console.log("ID der erstellten Aufgabe:", createdTaskID); */

        //Speichert die Keywords und hängt diese an den soeben erstellten Task an
        // Annahme: selectedKeywords ist ein Array von ausgewählten Keywords
        selectedKeywords.forEach((keyword, index) => {
          /* console.log(keyword.keyword__id); */
          // Führe den API-Aufruf für jedes Keyword aus

          API.setKeywordsInTask(
            user_id,
            createdTaskID,
            keyword.keyword__id,
            TagArray,
            index + 1, // Position des Wortes in der Reihenfolge
            token["hio-token"]
          )
            .then((data) => {
              // Hier kannst du die Antwort der API für das aktuelle Keyword verarbeiten
            })
            .catch((error) =>
              console.log(`Fehler beim API-Aufruf für "${keyword}":`, error)
            );
        });
        // Schließt das Modal nach dem Erstellen des Tasks
        setShowModalNewTask(false);
      })
      .catch((error) => console.log(error));
  };

  const handleImageChange = (evt) => {
    setSelectedImage(evt.target.files[0]);
  };

  const handleCaptureImage = () => {
    setShowCaptureImage(true);
  };

  const handleImageCapture = (pictureSrc) => {
    setSelectedImage(pictureSrc);
  };

  /* Setzt die ID des gewählten Goals für eine Hausaufgabe zur temporären Variable */
  const handleGoalClick = (goalId) => {
    setSelectedGoalId(goalId);
  };

  /* Setzt die ID des gewählten Execution für eine Hausaufgabe zur temporären Variable */
  const handleExecutionClick = (executionId) => {
    setselectedExecutionId(executionId);
  };

  /* Setzt die User ID des gewählten Users für eine Hausaufgabe zur temporären Variable */
  const handleUserClick = (userId) => {
    setselectedUserId(userId);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getKeywordsForTags = () => {
    const tags = props.exercise.exercise.tags; // Das ist Ihr vorhandenes Array mit Tags
    const tagIds = tags.map((tag) => tag.id); // Extrahiert die IDs der Tags

    // Speichern Sie die IDs in Ihrer State-Variable TagArray
    setTagArray(tagIds);

    API.getKeywordsForTags(TagArray, token["hio-token"])
      .then((resp) => setKeywords(resp))
      .catch((error) => console.log(error));
  };

  // Fügt das gewählte Keyword zum Array hinzu
  const handleKeywordButtonClick = (keywordId) => {
    // Finde das ausgewählte Keyword basierend auf der keywordId
    const selectedKeyword = Keywords.find(
      (keyword) => keyword.keyword__id === keywordId
    );

    // Füge das ausgewählte Keyword zu den ausgewählten Keywords hinzu
    setSelectedKeywords((prevSelectedKeywords) => [
      ...prevSelectedKeywords,
      selectedKeyword,
    ]);
  };

  // Löscht ein Wort aus dem Array und der Anzeige raus
  const handleRemoveKeyword = (keywordId) => {
    const updatedKeywords = selectedKeywords.filter(
      (keyword) => keyword.keyword__id !== keywordId
    );
    setSelectedKeywords(updatedKeywords);
  };

  const saveHomework = (
    user_id,
    by_user,
    lesson,
    exercise,
    goal,
    execution,
    task,
    repetitions,
    note,
    valid
  ) => {
    if (selectedUserId !== null && selectedUserId !== undefined) {
      API.writeNewHomework(
        user_id,
        by_user,
        lesson,
        exercise,
        goal,
        execution,
        task,
        repetitions,
        note,
        valid,
        token["hio-token"]
      )
        .then((resp) => {
          toast.success("Hausaufgabe erfolgreich gespeichert", {
            position: "top-center",
            autoClose: 3000,
          });
          setShowModal(false);
          // Leert die Liste der erstellten Tasks
          setcreatedTasks([]);
          // Leert die Notiz aus dem Task (Description)
          setNewTask();
          // Setzt den gewählten User zurück
          setselectedUserId(null);
          // Leert die Notiz im Task
          setNote("");
          //Leert die Liste der geklickten Keywords
          setSelectedKeywords([]);
        })
        .catch((error) => {
          toast.error("Fehler beim Speichern der Hausaufgabe", {
            position: "top-center",
            autoClose: 3000,
          });
          console.log(error);
        });
    } else {
      toast.error("Bitte einen Benutzer auswählen", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  return (
    <div key={"execomp" + props.exercise.exercise.id}>
      <ToastContainer />
      <div key={"exe" + props.exercise.exercise.id}>
        <div className="card p-2 pt-0 b-2 mb-2 ">
          <div className="d-flex justify-content-between ubungenCardHeader">
            <h5 className="fw-normal pt-2">
              {" "}
              <span className="title fw-bold fs-20 me-2">
                {props.exercise.exercise.id}
              </span>{" "}
              {props.exercise.exercise.title}
            </h5>
          </div>
          <div className="space-bottom">
            <div className="d-flex align-items-center">
              {props.exercise.exercise.tags &&
                props.exercise.exercise.tags.map((tag) => (
                  <span key={"tag" + tag.id}>
                    <button className="btn br-30 bg-primary">
                      {tag.title}
                    </button>
                  </span>
                ))}
            </div>
          </div>
          <div className="d-flex justify-content-between textToHideWrapper">
            <div className="b-1 py-1 px-2 br-12 mb-2 d-flex align-items-center w-fitContent minWidth-47">
              <img
                src="/images/Icons/Ebene_13.png"
                width="15px"
                height="15px"
                alt=""
                className="me-2"
              />
              <span>
                <b>Dauer:</b> {props.exercise.exercise.duration} Min -{" "}
                <b>Vorbereitung:</b> {props.exercise.exercise.preptime} Min
              </span>
            </div>
          </div>
          <div>
            {props.showselect && (
              <button
                onClick={() => handleSelectExercise(props.exercise.exercise.id)}
              >
                Add Exercise to User
              </button>
            )}
          </div>
          <div style={{ display: showContent ? "block" : "none" }}>
            <div>
              <span>
                <div className="col-12 mt-2">
                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="15px"
                      height="15px"
                      alt=""
                    />
                    <b>Grund</b>
                    <ReactMarkdown>
                      {props.exercise.exercise.description}
                    </ReactMarkdown>
                  </div>
                </div>
              </span>

              <span>
                <div className="col-12 mt-2">
                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="15px"
                      height="15px"
                      alt=""
                    />
                    <b>Übungsaufbau</b>
                    <ReactMarkdown>
                      {props.exercise.exercise.setup}
                    </ReactMarkdown>
                  </div>
                </div>
              </span>
              <div className="d-flex align-items-center">
                {props.exercise.exercise.equipments &&
                  props.exercise.exercise.equipments.map((equipment) => (
                    <div key={"equ" + equipment.id}>
                      <button className="btn br-30 bg-primary">
                        {equipment.title}
                      </button>
                    </div>
                  ))}
              </div>
              <span>
                <div className="col-12 mt-2">
                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="15px"
                      height="15px"
                      alt=""
                    />
                    <b>Ziel</b>
                    <ReactMarkdown>
                      {props.exercise.goal.description}
                    </ReactMarkdown>
                  </div>
                </div>
              </span>
              <span>
                <div className="col-12 mt-2">
                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                    <img
                      src="/images/Icons/Ebene_12.png"
                      width="15px"
                      height="15px"
                      alt=""
                    />
                    <b>Aufgabe</b>
                    <ReactMarkdown>
                      {props.exercise.execution.description}
                    </ReactMarkdown>
                  </div>
                </div>
              </span>
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt={props.exercise.exercise.title}
                  className="exercise-img"
                />
              )}
              {props.exercise.exercise.video_url && (
                <ReactPlayer
                  url={props.exercise.exercise.video_url}
                  width="100%"
                  className="exercise-vid"
                />
              )}
            </div>

            <div className="button-container">
              <button className="blue-button" onClick={openModal}>
                Hausaufgabe erstellen
              </button>
            </div>
            <hr></hr>
            <div className="d-flex justify-content-between textToHideWrapper">
              <div className="b-1 py-1 px-2 br-12 mb-2 d-flex align-items-center w-fitContent minWidth-47">
                <img
                  src="/images/Icons/Ebene_13.png"
                  width="15px"
                  height="15px"
                  alt=""
                  className="me-2"
                />

                <span>Stars: {props.exercise.exercise.no_of_ratings}</span>
              </div>
              <div className="b-1 py-1 px-2 br-12 mb-2 d-flex align-items-center w-fitContent minWidth-47">
                <img
                  src="/images/Icons/Ebene_13.png"
                  width="15px"
                  height="15px"
                  alt=""
                  className="me-2"
                />
                <span>Rating: {props.exercise.exercise.avg_rating}</span>
              </div>
            </div>
          </div>
          <div className="text-center d-flex align-items-center justify-content-center">
            <div
              className="toggle_btn mt-2 d-flex align-items-center"
              onClick={() => setShowContent(!showContent)} // Ändern Sie den Zustand, wenn auf den Button geklickt wird
            >
              <span className="toggle_text">
                {showContent ? "weniger" : "mehr"}
              </span>
              <span className="arrow ms-2 mt-1">
                <i
                  className={`fas fa-angle-${showContent ? "up" : "down"}`}
                ></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Bootstrap Modal */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Hausaufgabe erstellen</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: "rgba(200, 200, 200, 0.7)",
          }}
        >
          <label className="label-white">
            <span className="label-white-title">Name Aufgabe:</span>
            <ReactMarkdown>{props.exercise.exercise.title}</ReactMarkdown>
          </label>

          <label className="label-white">
            <span className="label-white-title">Kurs:</span>
            <ReactMarkdown>{props.lessonDetails.training_title}</ReactMarkdown>
          </label>

          <label className="label-white">
            <span className="label-white-title">Lektion:</span>
            <ReactMarkdown>{props.lessonDetails.title}</ReactMarkdown>
          </label>

          <div className="space-bottom">
            <div className="d-flex align-items-center">
              {props.exercise.exercise.tags &&
                props.exercise.exercise.tags.map((tag) => (
                  <span key={"tag" + tag.id}>
                    <button className="btn br-30 bg-primary">
                      {tag.title}
                    </button>
                  </span>
                ))}
            </div>
          </div>

          {props.MyUsersDetail.is_trainer ? (
            <label className="label-white">
              <span className="label-white-title">Schüler:</span>
              {props.lessonDetails.users &&
                props.lessonDetails.users.map((user) => (
                  <span
                    key={"user" + user.id}
                    className={`selected-item ${
                      selectedUserId === user.id ? "blue" : ""
                    }`}
                    onClick={() => handleUserClick(user.id)}
                  >
                    {user.first_name} {user.last_name}
                  </span>
                ))}
            </label>
          ) : null}

          <label className="label-white">
            <span className="label-white-title">Ziel:</span>
            {props.exercise.exercise.goal &&
              props.exercise.exercise.goal.map((goal) => (
                <span
                  key={"goal" + goal.id}
                  className={`selected-item ${
                    selectedGoalId === goal.id ? "blue" : ""
                  }`}
                  onClick={() => handleGoalClick(goal.id)}
                >
                  {goal.description}
                </span>
              ))}
          </label>

          <label className="label-white">
            <span className="label-white-title">Aufgabe:</span>
            {props.exercise.exercise.execution &&
              props.exercise.exercise.execution.map((execution) => (
                <span
                  key={"execution" + execution.id}
                  className={`selected-item ${
                    selectedExecutionId === execution.id ? "blue" : ""
                  }`}
                  onClick={() => handleExecutionClick(execution.id)}
                >
                  {execution.description}
                </span>
              ))}
          </label>
          <label className="label-white">
            <span className="label-white-title">Notiz:</span>
            <input
              type="text"
              /*  placeholder="Notiz eingeben" */
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </label>
          <label className="label-white">
            <span className="label-white-title">Individuelle Korrektur:</span>

            {createdTaskContent.map((task, index) => (
              <div key={index}>
                {/* Zum Beispiel: task.id, task.description, task.image, usw. */}
                {task.description}
              </div>
            ))}
            <hr></hr>

            {selectedKeywords.map((Keyword, index) => (
              <div key={index} className="keyword-container ">
                {Keyword.keyword__word}
              </div>
            ))}
          </label>
          <div className="button-container">
            <button
              className="blue-button"
              onClick={() => {
                if (selectedUserId !== null && selectedUserId !== undefined) {
                  handleOpenModalNewTask();
                  setShowModal(false);
                } else {
                  toast.error("Bitte einen Benutzer auswählen", {
                    position: "top-center",
                    autoClose: 3000,
                  });
                }
              }}
            >
              Individualisieren
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShowModal(false)}>Abbrechen</button>
          <button
            className="blue-button"
            onClick={() =>
              saveHomework(
                selectedUserId,
                1,
                props.lessonDetails.id,
                props.exercise.exercise.id,
                selectedGoalId,
                selectedExecutionId,
                createdTasks,
                1,
                note,
                "2025-12-31T12:00:00"
              )
            }
          >
            Speichern
          </button>
          {/* user_id, by_user, lesson, exercise, goal, execution, task, repetitions, note, valid */}
        </Modal.Footer>
      </Modal>

      <Modal show={showModalNewTask} onHide={handleCloseModalNewTask}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Individuelle Korrektur</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tab-buttons">
            <button
              onClick={() => handleTabClick("note")}
              className={`tab-button  ${activeTab === "note" ? "active" : ""}`}
            >
              Notiz
            </button>
            <button
              onClick={() => handleTabClick("image")}
              className={`tab-button  ${activeTab === "image" ? "active" : ""}`}
            >
              Foto
            </button>
            <button
              onClick={() => handleTabClick("video")}
              className={`tab-button  ${activeTab === "video" ? "active" : ""}`}
            >
              Video
            </button>
            <button
              onClick={() => {
                handleTabClick("keyword");
                getKeywordsForTags();
              }}
              className={`tab-button  ${
                activeTab === "keyword" ? "active" : ""
              }`}
            >
              Schlagwort
            </button>
          </div>
          {activeTab === "note" && (
            <label className="label-white">
              <span className="label-white-title">Notiz:</span>
              <textarea
                className="NoteInput"
                id="newTask"
                rows="5"
                onChange={(evt) => setNewTask(evt.target.value)}
              />
            </label>
          )}
          {activeTab === "image" && (
            <>
              <br></br>
              <br></br>
              {!showCaptureImage && (
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              )}
              {showCaptureImage ? (
                <div>
                  <CaptureImage handleImageChange={handleImageCapture} />
                </div>
              ) : (
                <button className="blue-button" onClick={handleCaptureImage}>
                  Foto
                </button>
              )}
            </>
          )}
          {activeTab === "video" && (
            <>
              <br></br>
              <br></br>
              <VideoRecorder onVideoRecorded={handleVideoRecorded} />
            </>
          )}
          {/* Anzeige der ausgewählten Keywords */}
          {selectedKeywords.length > 0 && (
            <div>
              <h4>Ausgewählte Schlagworte:</h4>
              <div>
                {selectedKeywords.map((selectedKeyword) => (
                  <div
                    className="keyword-container keyword-button-selected"
                    key={selectedKeyword.keyword__id}
                  >
                    <label>{selectedKeyword.keyword__word}</label>
                    <button
                      className="remove-button"
                      onClick={() =>
                        handleRemoveKeyword(selectedKeyword.keyword__id)
                      }
                    >
                      x
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeTab === "keyword" && (
            <>
              <h4>Schlagworte:</h4>
              <hr></hr>
              {Keywords &&
                Keywords.length > 0 &&
                Keywords.slice(0, displayedKeywords).map((keyword) => (
                  <button
                    className="keyword-button"
                    key={keyword.keyword__id}
                    onClick={() =>
                      handleKeywordButtonClick(keyword.keyword__id)
                    }
                  >
                    {keyword.keyword__word}
                  </button>
                ))}
              {showMore && Keywords.length > displayedKeywords && (
                <button className="more-button" onClick={showMoreKeywords}>
                  Mehr anzeigen
                </button>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              handleCloseModalNewTask();
              setShowModal(true);
            }}
          >
            Abbrechen
          </button>

          <button
            className="blue-button"
            onClick={() => {
              {
                /* Die Nummer 1 muss durch die UserID ersetzt werden */
              }
              createTaskClicked(
                selectedUserId,
                newTask,
                selectedImage,
                recordedVideo
              );
              setShowModal(true);
            }}
          >
            Speichern
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default Exercise;
