import React, { useState, useEffect } from "react";
import { API } from "../api-service";
import { useCookies } from "react-cookie";

function Auth() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginView, setIsLoginView] = useState(true);

  const [token, setToken] = useCookies(["hio-token"]);

  useEffect(() => {
    //Wenn ein Token vorhanden ist, wird man  zu exercises weitergeleitet. Das müsste dann später mein "Home" Seite sein
    if (token["hio-token"]) window.location.href = "/home";
  }, [token]);

  const loginClicked = () => {
    API.loginUser({ username, password })
      .then((resp) => {
        if (resp.token) {
          setToken("hio-token", resp.token);
        } else {
          console.log("Falsches Passwort");
        }
      })
      .catch((error) => console.log(error));
  };

  const registerClicked = () => {
    API.registerUser({ username, password })
      .then(() => loginClicked())
      .catch((error) => console.log(error));
  };

  const isDisabled = username.length === 0 || password.length === 0;

  return (
    <section className="loginForm d-flex align-items-lg-center pt-150 flex-column">
      <div className="container-fluid">
        <header className="App-header"></header>
        <div className="logo">
          <img src="images/Logo.png" alt="Logo" />
        </div>
        <div>
          {isLoginView ? (
            <h3 className="text-white">Login</h3>
          ) : (
            <h3 className="text-white">Register</h3>
          )}
          <label htmlFor="username" className="text-white">
            Username
          </label>
          <br></br>
          <input
            id="username"
            type="text"
            placeholder=""
            value={username}
            onChange={(evt) => setUsername(evt.target.value)}
            className="ubungBtn ubungBtn1 br-12 bg-white px-2"
          />
          <br></br>
          <label htmlFor="password" className="text-white">
            Password
          </label>
          <br></br>
          <input
            id="password"
            type="password"
            placeholder=""
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
            className="ubungBtn ubungBtn1 br-12 bg-white px-2"
          />
          <br></br>
          {isLoginView ? (
            <button
              onClick={loginClicked}
              disabled={isDisabled}
              className="red-button"
            >
              LOGIN
            </button>
          ) : (
            <button onClick={registerClicked} disabled={isDisabled}>
              Register
            </button>
          )}
          {isLoginView ? (
            //Damit Register funktioniert, muss der Wert unten auf "false" gesetzt werden
            <p onClick={() => setIsLoginView(true)}>Register</p>
          ) : (
            <p onClick={() => setIsLoginView(true)}>
              Have an Account? Login here
            </p>
          )}{" "}
        </div>
      </div>
    </section>
  );
}

export default Auth;
