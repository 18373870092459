import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { API } from "../api-service";

function SkillLevel(props) {
  const [token] = useCookies(["hio-token"]);
  const [skillData, setSkillData] = useState(null);

  useEffect(() => {
    if (!props.user_id) {
      return;
    }

    const getSkillData = (user_id) => {
      API.getSkillLevelforUser(user_id, token["hio-token"])
        .then((resp) => {
          setSkillData(resp);
        })
        .catch((error) => {
          console.log("Fehler", error);
        });
    };

    getSkillData(props.user_id);
  }, [props.user_id, token]);

  const renderSkillData = () => {
    if (!skillData) {
      return <div>Loading...</div>;
    }

    const userData = skillData[props.user_id];
    const isTrainer = props.MyUsersDetail.is_trainer;
    const trainerId = props.MyUsersDetail.id;

    if (!userData) {
      return null;
    }

    const userCategories = Object.values(userData);

    if (isTrainer && skillData[trainerId]) {
      const trainerCategories = Object.values(skillData[trainerId]);

      const categoryElements = userCategories.map((userCategory) => (
        <div key={userCategory.id}>
          <h4>{userCategory.category.description}</h4>
          <p>User Value: {userCategory.value}</p>
          {trainerCategories.some(
            (trainerCategory) =>
              trainerCategory.category.id === userCategory.category.id
          ) && (
            <p>
              Trainer Value:{" "}
              {
                trainerCategories.find(
                  (trainerCategory) =>
                    trainerCategory.category.id === userCategory.category.id
                ).value
              }
            </p>
          )}
        </div>
      ));

      return (
        <div className="skill-level">
          <div className="d-flex align-items-center justify-content-between px-5">
            <i className="fa-solid fa-arrow-left text-white"></i>
            <span className="text-white">Skill Level</span>
            <i className="fa-solid fa-arrow-right text-white me-2"></i>
          </div>
          <div className="container-fluid mt-4 mb-5 ">
            <div className="row mb-3">
              <div className="col-12 ">
                <div className="card p-4 mb-3">{categoryElements}</div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      );
    } else {
      return (
        <div className="skill-level">
          <div className="d-flex align-items-center justify-content-between px-5">
            <i className="fa-solid fa-arrow-left text-white"></i>
            <span className="text-white">Skill Level</span>
            <i className="fa-solid fa-arrow-right text-white me-2"></i>
          </div>
          <div className="container-fluid mt-4 mb-5 ">
            <div className="row mb-3">
              <div className="col-12 ">
                <div className="card p-4 mb-3">
                  {userCategories.map((userCategory) => (
                    <div key={userCategory.id}>
                      <h4>{userCategory.category.description}</h4>
                      <p>User Value: {userCategory.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderSkillData();
}

export default SkillLevel;
