import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { API } from "../api-service";

// Funktion wird verwendet, um die Public IP Adresse des Users in die DB zu schreiben

function PublicIP(props) {
  const [ip, setIp] = useState("");
  const [token] = useCookies(["hio-token"]);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIp(response.data.ip);
      } catch (error) {
        console.log(error);
      }
    };

    fetchIP();
  }, []);

  // Hier kannst du die IP-Adresse in einer Variable speichern
  useEffect(() => {
    // Überprüfe, ob die IP-Adresse geladen wurde
    if (ip) {
      // Speichere die IP-Adresse in einer Variable
      const myIP = ip;

      // Weitere Verarbeitung mit der IP-Adresse
      const user_id = props.user_id;
      API.writeUserIP(myIP, user_id, token["hio-token"])
        .then((resp) => {})
        .catch((error) => console.log(error));
      /*       console.log("user_id:", user_id);
      console.log("myIP:", myIP);
      console.log("token:", token); */
    }
  }, [ip]);

  return null; // Diese Komponente zeigt nichts im GUI an
}

export default PublicIP;
