import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { API } from "../api-service";
import { Modal } from "react-bootstrap";
import PushNotificationButton from "./activatePush";

function ChatData(props) {
  const [token] = useCookies(["hio-token"]);
  const [userList, SetUserList] = useState(null);
  const [showModal, setShowModal] = useState(false);

  if (!props.chat_data) {
    return null;
  }

  const userdata = props.chat_data;
  /* console.log(userdata); */

  function OpenChat(user_id, chat_user) {
    /* console.log(user_id); */
    props.chatName(chat_user);
    props.onUserClick(user_id);
  }

  function OpenTrainingChat(training_id, chat_title) {
    /* console.log(training_id); */
    props.chatName(chat_title);
    props.onTrainingClick(training_id);
  }

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNewChat = () => {
    API.getChatUser(token["hio-token"])
      .then((resp) => {
        SetUserList(resp);
        setShowModal(true); // showModal-Status auf true setzen, um das Modal zu öffnen
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="container-fluid mt-4 mb-5 ">
        <div className="card p-4 mb-3">
          <div className="plusCircle-top" onClick={handleNewChat}>
            +
          </div>
          <div className="d-flex justify-content-between"></div>
          {userdata &&
          ((userdata.user_data && userdata.user_data.length > 0) ||
            (userdata.training_data && userdata.training_data.length > 0)) ? (
            <div>
              <h4 className="fw-bold">Gruppen Chats</h4>
              <div>
                {userdata.training_data.map((training) => (
                  <div
                    onClick={() =>
                      OpenTrainingChat(training.id, training.title)
                    }
                    key={training.id}
                  >
                    <div className="schulerCard borderfull">
                      <div className="d-flex align-items-center px-2 w-100 h-100">
                        {/* <div className="userPic ListImage">
                          <img
                            src={training.image || "/images/no_profile.png"}
                            width="50px"
                            height="50px"
                            alt=""
                          />
                        </div> */}

                        <div>
                          <div className="d-flex flex-column margin-left text-dark">
                            <span className="mb-0 pb-0 ">{training.title}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <h4 className="fw-bold">Chats</h4>
              <div>
                {userdata.user_data.map((user) => (
                  <div
                    onClick={() =>
                      OpenChat(user.id, user.first_name + " " + user.last_name)
                    }
                    key={user.id}
                  >
                    <div className="schulerCard borderfull">
                      <div className="d-flex align-items-center px-2 w-100 h-100">
                        <div className="userPic ListImage">
                          <img
                            src={user.image || "/images/no_profile.png"}
                            width="50px"
                            height="50px"
                            alt=""
                          />
                        </div>
                        <div>
                          <div className="d-flex flex-column margin-left text-dark">
                            <span
                              className={`mb-0 pb-0 ${
                                user.HasUnread ? "bold-name" : ""
                              }`}
                            >
                              {user.first_name && user.last_name
                                ? `${user.first_name} ${user.last_name}`
                                : user.user_name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span>Du hat noch keine Chats</span>
          )}
        </div>
        <div>
          <PushNotificationButton />
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Mit wem willst du chatten?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userList && userList.length > 0 ? (
            <div>
              {userList.map((user) => (
                <div
                  onClick={() =>
                    OpenChat(user.id, `${user.first_name} ${user.last_name}`)
                  }
                  key={user.id}
                  className="schulerCard borderfull"
                >
                  <div className="d-flex align-items-center px-2 w-100 h-100">
                    <div className="userPic ListImage">
                      <img
                        src={user.image || "/images/no_profile.png"}
                        width="50px"
                        height="50px"
                        alt=""
                      />
                    </div>
                    <div>
                      <div className="d-flex flex-column margin-left text-dark">
                        <span className="mb-0 pb-0 ">
                          {user.first_name && user.last_name
                            ? `${user.first_name} ${user.last_name}`
                            : user.user_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No users found.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleCloseModal}>Close</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChatData;
