import React, { useState } from "react";
import ReactPlayer from "react-player";
import ReactMarkdown from "react-markdown";

function Homework(props) {
  const [showContent, setShowContent] = useState(false); // Zustand, der angibt, ob der Inhalt angezeigt wird oder nicht

  if (!props) {
    return null;
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between px-5">
        <i className="fa-solid fa-arrow-left text-white"></i>
        <span className="text-white">Hausaufgaben</span>
        <i className="fa-solid fa-arrow-right text-white me-2"></i>
      </div>

      <div className="container-fluid mt-4 mb-5 ">
        <div className="row mb-3">
          <div className="col-12 ">
            <div className="card p-4 mb-3">
              <h2>Meine Hausaufgaben</h2>

              <div>
                {props.MyHomework &&
                  props.MyHomework.map((homework) => {
                    let imageUrl = homework.exercise.image;
                    if (imageUrl && imageUrl.includes("/media")) {
                      imageUrl = "/media" + imageUrl.split("/media")[1];
                    }
                    return (
                      <span key={"hw" + homework.id}>
                        <div className="card p-2 pt-0 b-2 mb-2 ">
                          <div className="d-flex justify-content-between ubungenCardHeader">
                            <h5 className="fw-normal pt-2">
                              {" "}
                              <span className="title fw-bold fs-20 me-2">
                                {homework.exercise.id}
                              </span>{" "}
                              {homework.exercise.title}
                            </h5>
                          </div>
                          <div className="space-bottom">
                            <div className="d-flex align-items-center">
                              {homework.exercise.tags &&
                                homework.exercise.tags.map((tag) => (
                                  <span key={"tag" + tag.id}>
                                    <button className="btn br-30 bg-primary">
                                      {tag.title}
                                    </button>
                                  </span>
                                ))}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between textToHideWrapper">
                            <div className="b-1 py-1 px-2 br-12 mb-2 d-flex align-items-center w-fitContent minWidth-47">
                              <img
                                src="/images/Icons/Ebene_13.png"
                                width="15px"
                                height="15px"
                                alt=""
                                className="me-2"
                              />
                              <span>
                                <b>Dauer:</b> {homework.exercise.duration} Min -{" "}
                                <b>Vorbereitung:</b>{" "}
                                {homework.exercise.preptime} Min
                              </span>
                            </div>
                          </div>

                          <div
                            style={{ display: showContent ? "block" : "none" }}
                          >
                            <div>
                              <span>
                                <div className="col-12 mt-2">
                                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                                    <img
                                      src="/images/Icons/Ebene_12.png"
                                      width="15px"
                                      height="15px"
                                      alt=""
                                    />
                                    <b>Grund</b>
                                    <ReactMarkdown>
                                      {homework.exercise.description}
                                    </ReactMarkdown>
                                  </div>
                                </div>
                              </span>

                              <span>
                                <div className="col-12 mt-2">
                                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                                    <img
                                      src="/images/Icons/Ebene_12.png"
                                      width="15px"
                                      height="15px"
                                      alt=""
                                    />
                                    <b>Übungsaufbau</b>
                                    <ReactMarkdown>
                                      {homework.exercise.setup}
                                    </ReactMarkdown>
                                  </div>
                                </div>
                              </span>
                              <div className="d-flex align-items-center">
                                {homework.exercise.equipments &&
                                  homework.exercise.equipments.map(
                                    (equipment) => (
                                      <div key={"equ" + equipment.id}>
                                        <button className="btn br-30 bg-primary">
                                          {equipment.title}
                                        </button>
                                      </div>
                                    )
                                  )}
                              </div>
                              <span>
                                <div className="col-12 mt-2">
                                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                                    <img
                                      src="/images/Icons/Ebene_12.png"
                                      width="15px"
                                      height="15px"
                                      alt=""
                                    />
                                    <b>Ziel</b>
                                    <ReactMarkdown>
                                      {homework.goal.description}
                                    </ReactMarkdown>
                                  </div>
                                </div>
                              </span>
                              <span>
                                <div className="col-12 mt-2">
                                  <div className="b-1 py-1 px-2 br-12 mb-2 ">
                                    <img
                                      src="/images/Icons/Ebene_12.png"
                                      width="15px"
                                      height="15px"
                                      alt=""
                                    />
                                    <b>Aufgabe</b>
                                    <ReactMarkdown>
                                      {homework.execution.description}
                                    </ReactMarkdown>
                                  </div>
                                </div>
                              </span>
                              <span>
                                <b>Task</b>
                                {homework.task &&
                                  homework.task.map((tasks) => (
                                    <div key={"mytask" + tasks.id}>
                                      <div className="col-12 mt-2">
                                        <div className="b-1 py-1 px-2 br-12 mb-2 ">
                                          <ReactMarkdown>
                                            {/* task.description kann gelöscht werden..., zeigt noch die alten Hinweise an */}
                                            {tasks.description}
                                          </ReactMarkdown>

                                          {tasks.image && (
                                            <img
                                              src={tasks.image}
                                              alt={tasks.description}
                                              className="exercise-img"
                                            />
                                          )}
                                          {tasks.video && (
                                            <ReactPlayer
                                              url={tasks.video}
                                              width="100%"
                                              className="exercise-vid"
                                            />
                                          )}

                                          {tasks.correction &&
                                            tasks.correction.map(
                                              (correction) => (
                                                <div
                                                  key={
                                                    "correction" + correction.id
                                                  }
                                                  style={{
                                                    display: "inline-block",
                                                    marginRight: "5px",
                                                  }}
                                                >
                                                  {correction.word}
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </span>
                              {imageUrl && (
                                <img
                                  src={imageUrl}
                                  alt={homework.exercise.title}
                                  className="exercise-img"
                                />
                              )}
                              {homework.exercise.video_url && (
                                <ReactPlayer
                                  url={homework.exercise.video_url}
                                  width="100%"
                                  className="exercise-vid"
                                />
                              )}
                            </div>
                          </div>
                          <div className="text-center d-flex align-items-center justify-content-center">
                            <div
                              className="toggle_btn mt-2 d-flex align-items-center"
                              onClick={() => setShowContent(!showContent)} // Ändern Sie den Zustand, wenn auf den Button geklickt wird
                            >
                              <span className="toggle_text">
                                {showContent ? "weniger" : "mehr"}
                              </span>
                              <span className="arrow ms-2 mt-1">
                                <i
                                  className={`fas fa-angle-${
                                    showContent ? "up" : "down"
                                  }`}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Homework;
