export class API {
  static loginUser(body) {
    return fetch(`${process.env.REACT_APP_API_URL}/auth/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static registerUser(body) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static getExercises(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/exercises/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static updateExercise(exerc_id, body, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/exercises/${exerc_id}/`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      }
    ).then((resp) => resp.json());
  }

  static createExercise(body, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/exercises/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static deleteExercise(exerc_id, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/exercises/${exerc_id}/`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
  }

  //API Aufruf für die Lessons
  static getLessons(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/lessons/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  //API Aufruf für eine Lesson
  static getSingleLesson(lesson_id, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/lessons/${lesson_id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  //API Aufruf für ein Training
  static getMyTrainings(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/MyTrainingList/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  //API Aufruf für ein Training
  static getSingleTraining(training_id, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/trainings/${training_id}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then((resp) => resp.json());
  }

  //API Aufruf für einen User
  static getSingleUser(user_id, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/user/${user_id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  //API Aufruf für den aktuellen User
  static getMyUserDetail(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/me/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => Object.assign({}, data[0]));
  }

  // API zum holen aller User und Trainers, die mit einem anderen User in einem Training sind
  static getTrainingUserDataView(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/TrainingUserDataView/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  //Api zum Erstellen einer Notiz
  static createLessonNote(lesson_id, note, token) {
    const body = {
      lesson: lesson_id,
      note: note,
      uc: 1,
    };
    return fetch(`${process.env.REACT_APP_API_URL}/api/LessonNotes/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  //API Aufruf für die Übungen eines users
  static getMyExercises(user_id, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/myexercises/?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then((resp) => resp.json());
  }

  //Api zum zuweisen einer Übung zu einem User,
  static createMyExercises(user_id, exercise_id, token) {
    const body = {
      user: user_id,
      exercise: exercise_id,
    };
    return fetch(`${process.env.REACT_APP_API_URL}/api/exercisesforuser/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  //API Aufruf für meine Tasks des aktuellen users
  static getMyTasks(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/mytasks/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  //API Aufruf für von Tasks eines users
  static getTasksByUser(user_id, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/tasks/?user=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then((resp) => resp.json());
  }

  //Api zum Erstellen eines Tasks
  static createTask(user, description, image, video, token) {
    const formData = new FormData();
    formData.append("user", user);
    formData.append("description", description);
    formData.append("status", true);

    if (image) {
      formData.append("image", image);
    }

    if (video) {
      formData.append("video", video);
    }

    return fetch(`${process.env.REACT_APP_API_URL}/api/tasks/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    }).then((resp) => resp.json());
  }

  //API Aufruf die Homework des aktuellen Users
  //User_id ist optional. (Ohne user_id werden die Daten des aktuellen users zurückgegeben)
  static getMyHomework(token, user_id = null) {
    let url = `${process.env.REACT_APP_API_URL}/api/myHomework/`;

    // Fügt die user_id zur URL hinzu, wenn sie vorhanden ist
    if (user_id !== null) {
      url = `${url}${user_id}/`;
    }

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static writeNewHomework(
    user,
    by_user,
    lesson,
    exercise,
    goal,
    execution,
    task,
    repetitions,
    note,
    valid,
    token
  ) {
    const body = {
      user: user,
      by_user: by_user,
      lesson: lesson,
      exercise: exercise,
      goal: goal,
      execution: execution,
      task: task,
      repetitions: repetitions,
      note: note,
      valid: valid,
    };

    return fetch(`${process.env.REACT_APP_API_URL}/api/Homework/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static getKeywordsForTags(tags, token) {
    const tagParams = tags.map((tagId) => `tags=${tagId}`).join("&");
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/keywordsByTag/filter_by_tag/?${tagParams}`;

    return fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  //Api um Keywords für ein Task in die DB zu schreiben,
  static setKeywordsInTask(user_id, task, keyword, tags, order, token) {
    const body = {
      user: user_id,
      task: task,
      keyword: keyword,
      tag: tags,
      order: order,
    };
    return fetch(`${process.env.REACT_APP_API_URL}/api/KeywordsInTask/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static writeUserIP(ip, user_id, token) {
    const body = {
      public_ip: ip,
      user: user_id,
    };

    return fetch(`${process.env.REACT_APP_API_URL}/api/APIUserIP/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  //API Aufruf für Liste von Chats eines users
  static getChatDataByUser(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/chat-data/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  //API Aufruf für chats mit eingeloggtem User und einem anderen user über die user_id
  static getChatbyUser(user_id, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/chats/?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then((resp) => resp.json());
  }

  //API Aufruf um eine Nachricht als gelesen zu markieren
  static updateChatReadStatus(chat_id, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/update_chat/${chat_id}/`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          isRead: true,
        }),
      }
    ).then((resp) => resp.json());
  }

  //API Aufruf für chats mit eingeloggtem User und einem anderen user über die training_id
  static getChatbyTraining(training_id, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/trainingchats/?training_id=${training_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then((resp) => resp.json());
  }

  //API Aufruf gibt eine Liste von Usern zurück, mit denen gechattet werden darf. Also Leute, mit denen man im Kurs ist oder Kursleiter sind
  static getChatUser(token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/chatuser/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((resp) => resp.json());
  }

  static postNewMessage(sender, receiver, training, message, token) {
    const body = {
      sender: sender,
      receiver: receiver,
      training: training,
      message: message,
    };

    return fetch(`${process.env.REACT_APP_API_URL}/api/chats/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  //Api um Push Token in die DB zu schreiben,
  static postFCMToken(registration_id, token) {
    const body = {
      name: "hio",
      registration_id: registration_id,
      device_id: registration_id,
      active: true,
      type: "web",
    };
    return fetch(`${process.env.REACT_APP_API_URL}/api/FCMReg/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  //Api um attendance/Anwesenheit in die DB zu schreiben,
  static setAttendance(user_id, lesson_id, status, token) {
    const body = {
      user: user_id,
      lesson: lesson_id,
      status: status,
    };
    return fetch(`${process.env.REACT_APP_API_URL}/api/attendance/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  //API Aufruf für Skill Levels von einem User
  static getSkillLevelforUser(user_id, token) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/SkillChange/?user_id=${user_id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    ).then((resp) => resp.json());
  }
}
