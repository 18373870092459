// getTasksByUser

import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { API } from "../api-service";

const ChatMessage = ({
  message,
  isMyMessage,
  timestamp,
  isRead,
  s_first_name,
  s_last_name,
  s_username,
}) => {
  // Konvertiere den Timestamp in ein JavaScript Date-Objekt
  const date = new Date(timestamp);

  // Definiere die Optionen für das Datum- und Zeitformat
  const options = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  // Formatieren des Datums und der Uhrzeit
  const formattedTimestamp = date.toLocaleString("de-DE", options);
  return (
    <div style={{ textAlign: isMyMessage ? "right" : "left", margin: "10px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: isMyMessage ? "row-reverse" : "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "inline-block",
            backgroundColor: isMyMessage ? "#DCF8C6" : "#F3F3F3",
            padding: "10px",
            borderRadius: "8px",
            maxWidth: "80%",
          }}
        >
          {!isMyMessage && (
            <div>
              {s_first_name && s_last_name ? (
                <div className="trainingChat">
                  {s_first_name} {s_last_name}:
                </div>
              ) : (
                <div>{s_username}</div>
              )}
            </div>
          )}
          <p>{message}</p>
        </div>
        {/* {!isMyMessage && ( */}
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: isRead ? "green" : "gray",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        />
        {/*    )} */}
      </div>
      <div style={{ fontSize: "12px", color: "#000", marginTop: "5px" }}>
        {formattedTimestamp}
      </div>
    </div>
  );
};

function Chat_Training(props) {
  const [token] = useCookies(["hio-token"]);
  const [TrainingChat, setTrainingChat] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const endeDerSeiteRef = useRef(null);

  const getChatbyTraining = (training_id) => {
    if (!training_id) {
      // Wenn user_id nicht vorhanden oder falsy ist, beende die Funktion
      return;
    }

    API.getChatbyTraining(training_id, token["hio-token"])
      .then((resp) => setTrainingChat(resp))
      .catch((error) => console.log());
  };

  const sendMessage = () => {
    if (!newMessage) {
      return; // Wenn die Nachricht leer ist, beende die Funktion
    }
    let user_id = null; //muss leer sein, da die Antwort an ein Training geht

    API.postNewMessage(
      props.myUser_id,
      user_id,
      props.training_id,
      newMessage,
      token["hio-token"]
    )
      .then((resp) => {
        // Nachricht erfolgreich gesendet, Chat-Inhalt aktualisieren
        getChatbyTraining(props.training_id);
        setNewMessage(""); // Eingabefeld leeren
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getChatbyTraining(props.training_id);

    // Aktualisiere den Chat alle 5 Sekunden
    const interval = setInterval(() => {
      getChatbyTraining(props.training_id);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [props.training_id]); // training_id als Abhängigkeit hinzufügen

  useEffect(() => {
    /* console.log(UserChat); */
  }, [TrainingChat]);

  useEffect(() => {
    endeDerSeiteRef.current.scrollIntoView({ behavior: "smooth" });
  }, [TrainingChat]);

  return (
    <div className="chat-container">
      <div className="chat-sticky-header">
        <h2>{props.chatName}</h2>
      </div>

      <div style={{ paddingBottom: "200px" }}>
        {TrainingChat && (
          <div>
            {TrainingChat.map((message) => (
              <ChatMessage
                key={message.id}
                message={message.message}
                isMyMessage={message.sender === props.myUser_id ? true : false}
                timestamp={message.dc}
                isRead={message.isRead}
                s_first_name={message.sender_first_name}
                s_last_name={message.sender_last_name}
                s_username={message.sender_username}
              />
            ))}
          </div>
        )}

        <div className="NewMessageChat">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <button onClick={sendMessage}>Senden</button>
        </div>
        <div ref={endeDerSeiteRef} />
      </div>
    </div>
  );
}

export default Chat_Training;
