import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "environment",
};
const CaptureImage = ({ handleImageChange }) => {
  const [picture, setPicture] = useState("");
  const webcamRef = React.useRef(null);

  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
    // Konvertiere das aufgenommene Bild in ein Dateiobjekt
    const file = dataURLtoFile(pictureSrc, "captured-image.jpg");
    handleImageChange(file);
  });

  return (
    <div>
      <div>
        <center>
          {picture == "" ? (
            <Webcam
              audio={false}
              height={400}
              ref={webcamRef}
              width={400}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          ) : (
            <img src={picture} />
          )}
        </center>
      </div>
      <div>
        {picture != "" ? (
          <center>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPicture();
              }}
              className="btn btn-primary"
            >
              Retake
            </button>
          </center>
        ) : (
          <center>
            <button
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
              className="btn btn-danger"
            >
              Capture
            </button>
          </center>
        )}
      </div>
    </div>
  );
};
export default CaptureImage;
